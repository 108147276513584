.icn {
  width: 1em;
  height: 1em;

  display: inline-block;
  fill: currentColor;
}



