/* ======================================
  Selectric v1.13.0
====================================== */

/* Items box */
.s-selectric {
  .selectric-wrapper {
    position: relative;

    cursor: pointer;
  }

  .selectric-responsive {
    width: 100%;
  }

  .selectric {
    position: relative;
    display: flex;
    align-items: center;
    padding: em(13 20, $fz);
    
    overflow: hidden;
        
    background-color: transparent;
    color: $black;
    outline: 1px solid rgba(0, 0, 0, 0.15);
    outline-offset: -1px;

    @media (max-width: $media_medium) {
      padding-right: em(15, $fz);
    }


    &:hover,
    &:focus {
      background: linear-gradient(250deg, $white 0%, $accent 100%), transparent;
      outline: none;
    }

    &:focus {
      outline: none;
      border: 0;

      &::placeholder {
        color: inherit;
      }
    }

    .label {
      $fz: 18;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: $font_family;
      font-size: em($fz, $fz_base);
      text-transform: uppercase;

      line-height: em(30, $fz);
      white-space: nowrap;
      user-select: none;

      @media (max-width: $media_medium) {
        $fz: 14;
        font-size: em($fz, $fz_base);
        line-height: em(25, $fz);
      }

      @media (max-width: $media_small) {
        $fz: 12;
        font-size: em($fz, $fz_base);
        line-height: em(19, $fz);
      }
    }

    .button {
      position: absolute;
      top: 0;
      right: 0;
      width: em(50, $fz);
      height: 100%;

      opacity: 0;

      display: block;
      color: $black;
      text-align: center;
    }

    &::after {
      width: em(12, $fz);
      height: em(8, $fz);
      margin-left: auto;
      content: '';
      background: url('../../img/arrow.svg') center center / cover no-repeat;
      transition: all 0.4s;

      @media (max-width: $media_medium) 
      {
        width: em(10, $fz);
      height: em(6, $fz); 
      }
    }
  }

  .selectric-open {
    z-index: 9999;

    .selectric::after {
      transform: rotate(180deg);
    }

    .selectric-items {
      display: block;
      background: $white;
    }
  }

  .selectric-disabled {
    filter: alpha(opacity=50);

    opacity: 0.5;

    cursor: default;
    user-select: none;
  }

  .selectric-hide-select {
    position: relative;
    width: 0;
    height: 0;

    overflow: hidden;

    select {
      position: absolute;
      left: -100%;
    }
  }

  .selectric-hide-select.selectric-is-native {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;

    select {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;

      opacity: 0;

      box-sizing: border-box;
      border: 0;
    }
  }

  .selectric-input {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 1px !important;
    height: 1px !important;
    margin: 0 !important;

    padding: 0 !important;
    overflow: hidden !important;
    outline: none !important;
    border: 0 !important;
    background: none !important;
    clip: rect(0, 0, 0, 0) !important;
  }

  .selectric-temp-show {
    position: absolute !important;

    visibility: hidden !important;

    display: block !important;
  }

  .selectric-items {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: -1;
    width: 100%;

    display: none;
    border-radius: em(5, $fz);

    ::-webkit-scrollbar-track {
      margin-top: em(10, $fz);
      margin-bottom: em(7, $fz);
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: #ADBFCD;
    }

    ::-webkit-resizer {
      width: em(4, $fz);
      height: em(0, $fz);
      background-image: none;
      background-repeat: no-repeat;
    }

    ::-webkit-scrollbar {
      width: em(4, $fz);
    }

    .selectric-scroll {
      height: 100%;
      max-height: em(140, $fz);
      margin-right: em(4, $fz);

      padding-top: em(5, $fz);
      padding-bottom: em(5, $fz);
      overflow: auto;
    }

    li {
      min-height: em(30, $fz);
      margin-right: em(3, $fz);
      margin-left: em(12, $fz);

      cursor: pointer;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: em(7 10 7 9, $fz);
      border-radius: em(10, $fz);
      color: $black;

      &:nth-child(n+2) {
        margin-top: em(2, $fz);
      }

      &:hover {
        background: linear-gradient(250deg, $white 0%, $accent 100%), transparent;
        color: $white;
      }
    }

    .selected {
      display: none;
    }

    .highlighted {
      background: linear-gradient(270deg, #FF8404 0%, $accent 100%), #FFB930;
      color: $white;
    }

    .disabled {
      opacity: 0.5;

      cursor: default !important;
      background: none !important;
      color: #666 !important;
      filter: alpha(opacity=50);
      user-select: none;
    }

    .selectric-group {
      .selectric-group-label {
        cursor: default;

        padding-left: em(10, $fz);
        background: none;
        color: black;
        user-select: none;
      }

      li {
        padding-left: 25px;
      }
    }

    .selectric-group.disabled {
      li {
        opacity: 1;
        filter: alpha(opacity=100);
      }
    }
  }

  .selectric-above {
    .selectric-items {
      top: auto;
      bottom: 100%;
    }
  }
}