@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/gilroy-regular.woff2') format('woff2'),
    url('../fonts/gilroy-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/gilroy-medium.woff2') format('woff2'),
    url('../fonts/gilroy-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/gilroy-semibold.woff2') format('woff2'),
    url('../fonts/gilroy-semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/gilroy-bold.woff2') format('woff2'),
    url('../fonts/gilroy-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}