@charset "UTF-8";
@import url(../../node_modules/aos/dist/aos.css);
/*
Функция обрезающая единицы измерения и возвращающая число
@param {Number}
@return {Number}
*/
@font-face {
  font-family: 'Gilroy';
  src: url("../fonts/gilroy-regular.woff2") format("woff2"), url("../fonts/gilroy-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Gilroy';
  src: url("../fonts/gilroy-medium.woff2") format("woff2"), url("../fonts/gilroy-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Gilroy';
  src: url("../fonts/gilroy-semibold.woff2") format("woff2"), url("../fonts/gilroy-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Gilroy';
  src: url("../fonts/gilroy-bold.woff2") format("woff2"), url("../fonts/gilroy-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

.icn {
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor; }

/* custom-reset.css | 27.08.2018 | https://yurch-html.github.io/dist/custom-reset.html */
*,
*::before,
*::after {
  box-sizing: inherit; }

html {
  height: 100%;
  box-sizing: border-box; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html {
    display: flex;
    flex-direction: column; } }

body {
  min-height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  line-height: 1;
  text-decoration-skip: objects;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent; }

p,
dd,
dl,
figure,
blockquote {
  margin: 0; }

blockquote,
q {
  quotes: none; }

ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

th {
  font-weight: inherit; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: inherit;
  font-weight: inherit; }

audio,
video {
  display: block; }

img {
  max-width: 100%;
  display: block;
  border: none; }

iframe {
  border: none; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: inherit; }

a {
  background-color: transparent;
  color: inherit;
  text-decoration: none; }

abbr {
  border: none;
  text-decoration: none; }

b,
strong {
  font-weight: inherit; }

i,
em {
  font-style: inherit; }

dfn {
  font-style: inherit; }

mark {
  background-color: transparent;
  color: inherit; }

small {
  font-size: inherit; }

sub,
sup {
  position: relative;
  vertical-align: baseline;
  font-size: inherit;
  line-height: 0; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
  background-color: transparent;
  color: inherit;
  box-shadow: none;
  font: inherit;
  letter-spacing: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-align: left;
  text-transform: none; }

button,
[type='button'],
[type='reset'],
[type='submit'] {
  cursor: pointer;
  appearance: none; }

textarea {
  overflow-x: hidden;
  overflow-y: auto;
  resize: none; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border: none; }

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto; }

[type='search']::-webkit-search-decoration {
  appearance: none; }

::-webkit-file-upload-button {
  appearance: none;
  font: inherit; }

fieldset {
  margin: 0;
  padding: 0;
  border: none; }

legend {
  display: block;
  padding: 0;
  white-space: normal; }

select {
  appearance: none;
  appearance: none;
  appearance: none; }

select::-ms-expand {
  display: none; }

::input-placeholder {
  opacity: 1;
  color: inherit;
  transition: opacity 0.3s; }

::-moz-input-placeholder {
  opacity: 1;
  color: inherit;
  transition: opacity 0.3s; }

:placeholder {
  opacity: 1;
  color: inherit;
  transition: opacity 0.3s; }

:input-placeholder {
  opacity: 1;
  color: inherit;
  transition: opacity 0.3s; }

:focus::input-placeholder {
  opacity: 0; }

:focus::-moz-input-placeholder {
  opacity: 0; }

:focus:placeholder {
  opacity: 0; }

:focus:input-placeholder {
  opacity: 0; }

svg {
  width: 100%;
  height: 100%;
  display: block;
  fill: currentColor; }

[hidden] {
  display: none; }

:disabled,
.disabled {
  cursor: not-allowed; }

::-ms-clear {
  display: none; }

:-webkit-autofill {
  box-shadow: 0 0 100px #FFF inset;
  -webkit-text-fill-color: currentColor; }

::selection {
  background-color: #43ABF0;
  color: #FFF; }

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0); }

html {
  font-size: 16px; }

body {
  min-width: 20em;
  display: flex;
  background-color: #FFFFFF;
  color: #000000;
  font-family: "Gilroy", sans-serif; }

.main {
  overflow: hidden; }

.footer {
  margin-top: auto; }

.container {
  width: 100%;
  max-width: 101.875em;
  margin-right: auto;
  margin-left: auto;
  padding-right: 0.9375em;
  padding-left: 0.9375em; }
  @media (max-width: 1280px) {
    .container {
      max-width: 61.25em; } }
  @media (max-width: 1000px) {
    .container {
      max-width: 42.3125em; } }
  @media (max-width: 768px) {
    .container {
      max-width: 29.5625em; } }
  @media (max-width: 480px) {
    .container {
      max-width: 23.125em; } }
  @media (max-width: 360px) {
    .container {
      max-width: 19.375em; } }

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.3125em 0.9375em; }
  .btn--size-l {
    min-width: 15.625em;
    min-height: 3.875em; }
    @media (max-width: 1280px) {
      .btn--size-l {
        min-width: 14em;
        min-height: 3.5em; } }
    @media (max-width: 768px) {
      .btn--size-l {
        min-width: 13.25em;
        min-height: 3.3125em; } }
    @media (max-width: 480px) {
      .btn--size-l {
        min-width: 8.125em;
        max-height: 2.5em; } }
    @media (max-width: 360px) {
      .btn--size-l {
        justify-self: center;
        max-width: 10em;
        min-height: 3.125em; } }
  .btn--size-m {
    min-width: 24em;
    min-height: 5em;
    border-radius: 0.3125em; }
    @media (max-width: 768px) {
      .btn--size-m {
        min-width: 15.6875em;
        min-height: 3.75em; } }
    @media (max-width: 360px) {
      .btn--size-m {
        width: 100%; } }
  .btn__icon {
    font-size: 2.1875em; }
    @media (max-width: 480px) {
      .btn__icon {
        font-size: 1.25em; } }
  .btn--size-m .btn__caption {
    margin-right: 1.45833em;
    text-transform: uppercase;
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1.125em; }
    @media (max-width: 768px) {
      .btn--size-m .btn__caption {
        font-size: 0.875em;
        line-height: 1.21429em; } }
  .btn--size-l .btn__caption {
    font-size: 1.125em;
    font-weight: 600;
    line-height: 1.66667em; }
  .btn--theme-accent {
    background: #0C60E5;
    color: #FFFFFF; }
    .btn--theme-accent:hover, .btn--theme-accent:focus {
      box-shadow: 0em 0.3125em 3em 0.4375em rgba(12, 96, 229, 0.5); }
  .btn--theme-default {
    border: 1px solid #FFFFFF;
    background: transparent; }
    .btn--theme-default:hover, .btn--theme-default:focus {
      box-shadow: 0em 0.3125em 3em 0.4375em rgba(255, 255, 255, 0.5); }

.text--accent {
  color: #0C60E5; }

.header {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  background-color: transparent;
  color: #FFFFFF; }
  .header:not(.header--transparent) {
    background-color: rgba(0, 0, 0, 0.7);
    box-shadow: 0em 0.25em 1.875em rgba(0, 0, 0, 0.25); }
  .header__container {
    display: flex;
    align-items: center;
    letter-spacing: 0.125em; }
    @media (max-width: 768px) {
      .header__container {
        flex-direction: column;
        align-items: flex-start;
        padding-top: 1.875em; } }
  @media (max-width: 768px) and (max-width: 480px) {
    .header__container {
      flex-direction: row;
      padding-top: 3.75em;
      padding-bottom: 3.75em; } }
  .header__logo {
    flex: none;
    margin-right: 0.9375em; }
  .header__nav {
    margin-left: auto; }

.logo__img {
  width: 10em;
  height: 1.625em; }
  @media (max-width: 1280px) {
    .logo__img {
      width: 8.9375em;
      height: 1.4375em; } }
  @media (max-width: 1000px) {
    .logo__img {
      width: 6em;
      height: 1em; } }

.nav__list {
  flex: auto;
  display: flex;
  flex-wrap: wrap; }
  @media (max-width: 480px) {
    .nav__list {
      flex: none;
      width: 100%;
      flex-direction: column;
      align-items: end; } }

.nav__item:nth-child(n+2) {
  margin-left: 6em; }
  @media (max-width: 1280px) {
    .nav__item:nth-child(n+2) {
      margin-left: 3.5em; } }
  @media (max-width: 1000px) {
    .nav__item:nth-child(n+2) {
      margin-left: 2.25em; } }
  @media (max-width: 480px) {
    .nav__item:nth-child(n+2) {
      margin-top: 0.625em;
      margin-left: 0; } }

.nav__link {
  min-height: 9.42857em;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.875em;
  font-weight: 500; }
  @media (max-width: 1000px) {
    .nav__link {
      font-size: 0.75em; } }
  @media (max-width: 768px) {
    .nav__link {
      min-height: 11.11111em;
      font-size: 0.5625em; } }
  @media (max-width: 480px) {
    .nav__link {
      min-height: 1.22222em; } }
  .nav__link:hover, .nav__link:focus {
    color: #0C60E5; }

@media (max-width: 480px) {
  .nav__container {
    position: fixed;
    top: 0;
    right: 0;
    width: 12.5em;
    min-height: 16.125em;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: start;
    padding-top: 7.0625em;
    padding-right: 4.375em;
    padding-bottom: 2.5em;
    background-color: rgba(0, 0, 0, 0.5); } }
  @media (max-width: 480px) and (max-width: 360px) {
    .nav__container {
      width: 9.375em;
      padding-right: 2.5em; } }

@media (max-width: 480px) {
    .nav__container--is-open {
      visibility: visible; } }

.nav__toggle {
  display: none; }
  @media (max-width: 480px) {
    .nav__toggle {
      display: block; } }

.nav-toggle {
  position: relative;
  z-index: 50;
  width: 1.875em;
  height: 1.1875em;
  color: #FFFFFF; }
  .nav-toggle--is-toggled {
    color: #FFFFFF; }
  .nav-toggle__bar {
    position: absolute;
    top: 40%;
    right: 0;
    width: 1.875em;
    height: 0.1875em;
    margin-top: -0.3125em;
    border-radius: 0.4375em;
    background: #FFFFFF;
    transition: all, 0.2s; }
  .nav-toggle__bar + .nav-toggle__bar {
    margin-top: 0.125em; }
  .nav-toggle__bar + .nav-toggle__bar + .nav-toggle__bar {
    margin-top: 0.5625em; }
  .nav-toggle--is-toggled .nav-toggle__bar {
    transform: rotate(45deg) translateX(0.25em) translateY(0.08125em); }
  .nav-toggle--is-toggled .nav-toggle__bar + .nav-toggle__bar {
    opacity: 1;
    transform: rotate(-45deg) translateX(0.25em) translateY(-0.0625em); }
  .nav-toggle--is-toggled .nav-toggle__bar + .nav-toggle__bar + .nav-toggle__bar {
    opacity: 0;
    transform: scale(0.1); }

.hero {
  position: relative;
  min-height: 67.9375em;
  padding-top: 23.75em;
  padding-bottom: 6.25em;
  background: url("/img/hero-bg.jpg") center center/cover no-repeat, #000000;
  color: #FFFFFF;
  text-transform: uppercase; }
  @media (max-width: 1280px) {
    .hero {
      min-height: 53.3125em;
      padding-top: 19.375em;
      padding-bottom: 15.125em; } }
  @media (max-width: 1000px) {
    .hero {
      padding-top: 20.6875em; } }
  @media (max-width: 768px) {
    .hero {
      min-height: 45.625em;
      padding-top: 16.6875em; } }
  @media (max-width: 480px) {
    .hero {
      min-height: auto;
      padding-top: 17.9375em;
      padding-bottom: 8.4375em;
      background-position: 80%; } }
  .hero::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    display: block;
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.7) -10.38%, rgba(0, 0, 0, 0) 162.79%); }
  .hero__container {
    position: relative;
    letter-spacing: 0.125em; }
  .hero__content {
    max-width: 67.5em; }
    @media (max-width: 1280px) {
      .hero__content {
        max-width: 44.0625em; } }
    @media (max-width: 1000px) {
      .hero__content {
        max-width: 33.75em; } }
    @media (max-width: 768px) {
      .hero__content {
        max-width: 23.5em; } }
    @media (max-width: 480px) {
      .hero__content {
        max-width: 18.3125em; } }
  .hero__title {
    margin-bottom: 1.56944em;
    font-size: 4.5em;
    font-weight: 700;
    line-height: 1.20833em; }
    @media (max-width: 1280px) {
      .hero__title {
        margin-bottom: 1.25em;
        font-size: 3em;
        line-height: 1.20833em; } }
    @media (max-width: 1000px) {
      .hero__title {
        font-size: 2.25em;
        line-height: 1.19444em; } }
    @media (max-width: 768px) {
      .hero__title {
        font-size: 1.5em;
        line-height: 1.25em; } }
    @media (max-width: 480px) {
      .hero__title {
        font-size: 1.125em;
        line-height: 1.22222em; } }
  .hero__mark {
    margin-bottom: 1.5em; }
    @media (max-width: 768px) {
      .hero__mark {
        font-size: 0.875em;
        line-height: 1.21429em; } }

.about {
  position: relative;
  min-height: 52.375em;
  padding-top: 13.125em;
  padding-bottom: 3.125em; }
  @media (max-width: 1000px) {
    .about {
      min-height: 38.25em;
      padding-top: 11.125em; } }
  @media (max-width: 768px) {
    .about {
      min-height: 28.75em;
      padding-top: 7.0625em; } }
  @media (max-width: 480px) {
    .about {
      padding-top: 6.25em; } }
  .about__container {
    position: relative; }
  .about__content {
    max-width: 45em;
    margin-left: 42%; }
    @media (max-width: 1000px) {
      .about__content {
        max-width: 34.1875em;
        margin-left: 0; } }
    @media (max-width: 768px) {
      .about__content {
        max-width: 26.5625em; } }
    @media (max-width: 480px) {
      .about__content {
        max-width: 20em; } }
    @media (max-width: 360px) {
      .about__content {
        padding-bottom: 1.875em; } }
  .about__title {
    margin-bottom: 0.78125em;
    font-size: 4em;
    font-weight: 700;
    line-height: 1.20313em; }
    @media (max-width: 1280px) {
      .about__title {
        margin-bottom: 0.625em;
        font-size: 3em;
        line-height: 1.20833em; } }
    @media (max-width: 768px) {
      .about__title {
        margin-bottom: 0.69444em;
        font-size: 2.25em;
        line-height: 1.19444em; } }
  .about__subtitle {
    margin-bottom: 1.25em;
    color: #0C60E5;
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1.20833em; }
    @media (max-width: 1280px) {
      .about__subtitle {
        margin-bottom: 1.11111em;
        font-size: 1.125em;
        line-height: 1.16667em; } }
    @media (max-width: 768px) {
      .about__subtitle {
        margin-bottom: 1.07143em;
        font-size: 0.875em;
        line-height: 1.21429em; } }
  .about__text {
    margin-bottom: 0.83333em;
    font-size: 1.5em;
    line-height: 1.16667em; }
    @media (max-width: 1280px) {
      .about__text {
        margin-bottom: 1.11111em;
        font-size: 1.125em;
        line-height: 1.16667em; } }
    @media (max-width: 768px) {
      .about__text {
        font-size: 0.875em;
        line-height: 1.21429em; } }

.about-decor {
  position: absolute;
  background-color: #0C60E5; }
  .about-decor__1 {
    top: -3.5em;
    left: 4.875em;
    width: 6.9375em;
    height: 6.9375em; }
    @media (max-width: 1280px) {
      .about-decor__1 {
        width: 3.875em;
        height: 3.875em; } }
    @media (max-width: 1000px) {
      .about-decor__1 {
        width: 2.375em;
        height: 2.375em; } }
    @media (max-width: 768px) {
      .about-decor__1 {
        top: unset;
        bottom: -7.5em;
        left: 0; } }
    @media (max-width: 360px) {
      .about-decor__1 {
        bottom: -2.5em;
        left: 1.875em; } }
  .about-decor__2 {
    bottom: -19.6875em;
    left: 1.1875em;
    z-index: 1;
    width: 28.375em;
    height: 28.375em; }
    @media (max-width: 1280px) {
      .about-decor__2 {
        bottom: -25.6875em;
        width: 19.0625em;
        height: 19.0625em; } }
    @media (max-width: 1000px) {
      .about-decor__2 {
        bottom: -13.75em;
        height: 8.875em; } }
    @media (max-width: 768px) {
      .about-decor__2 {
        right: 0;
        bottom: -10.625em;
        left: unset;
        width: 18.125em;
        height: 6.125em; } }
    @media (max-width: 480px) {
      .about-decor__2 {
        right: -9.375em; } }
    @media (max-width: 360px) {
      .about-decor__2 {
        bottom: -4.375em; } }
  .about-decor__3 {
    right: 5em;
    bottom: -10.625em;
    width: 3.1875em;
    height: 3.1875em; }
    @media (max-width: 1280px) {
      .about-decor__3 {
        width: 3.875em;
        height: 3.875em; } }
    @media (max-width: 1000px) {
      .about-decor__3 {
        bottom: 3.75em;
        width: 2.375em;
        height: 2.375em; } }
    @media (max-width: 768px) {
      .about-decor__3 {
        top: -8.125em;
        right: 50%;
        bottom: unset; } }
    @media (max-width: 360px) {
      .about-decor__3 {
        right: 10%; } }
  .about-decor__line {
    top: 8.8125em;
    right: 0;
    width: 51%;
    height: 0.125em; }
    @media (max-width: 1000px) {
      .about-decor__line {
        width: 45%; } }
    @media (max-width: 768px) {
      .about-decor__line {
        top: 4.375em;
        width: 58%; } }

.products {
  position: relative;
  min-height: 85.75em;
  padding-top: 14.6875em;
  padding-bottom: 3.125em;
  background-color: #000000;
  color: #FFFFFF; }
  @media (max-width: 1280px) {
    .products {
      min-height: 68.125em;
      padding-top: 11.875em; } }
  @media (max-width: 1000px) {
    .products {
      min-height: 65.125em; } }
  @media (max-width: 768px) {
    .products {
      min-height: 33.75em;
      padding-top: 5.3125em; } }
  @media (max-width: 480px) {
    .products {
      padding-bottom: 4.6875em; } }
  .products__title {
    margin-bottom: 3.03125em;
    font-size: 4em;
    font-weight: 700;
    line-height: 1.20313em; }
    @media (max-width: 1280px) {
      .products__title {
        margin-bottom: 3.375em;
        font-size: 3em;
        line-height: 1.20833em; } }
    @media (max-width: 768px) {
      .products__title {
        margin-bottom: 2.27778em;
        font-size: 2.25em;
        line-height: 1.22222em; } }
    @media (max-width: 480px) {
      .products__title {
        max-width: 6.38889em;
        margin-bottom: 1.52778em; } }
  .products__content {
    display: flex;
    align-items: flex-start; }
    @media (max-width: 480px) {
      .products__content {
        flex-direction: column; } }
  .products__items {
    margin-right: 9.625em; }
    @media (max-width: 1280px) {
      .products__items {
        margin-right: 6.25em; } }
    @media (max-width: 1000px) {
      .products__items {
        margin-right: 4.0625em; } }
    @media (max-width: 768px) {
      .products__items {
        margin-right: 1.25em; } }
    @media (max-width: 480px) {
      .products__items {
        min-height: 8.75em;
        margin-bottom: 1.5625em;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1.25em;
        row-gap: 0.625em; } }
    @media (max-width: 360px) {
      .products__items {
        grid-template-columns: 1fr; } }
  .products__item-first, .products__item {
    min-width: 32.4375em;
    border: 1px solid #FFFFFF; }
    @media (max-width: 1280px) {
      .products__item-first, .products__item {
        min-width: 24.1875em; } }
    @media (max-width: 1000px) {
      .products__item-first, .products__item {
        min-width: 16.875em; } }
    @media (max-width: 768px) {
      .products__item-first, .products__item {
        min-width: 10.8125em; } }
    @media (max-width: 360px) {
      .products__item-first, .products__item {
        max-width: 10em; } }
    .products__item-first:nth-child(n+2), .products__item:nth-child(n+2) {
      margin-top: 1.875em; }
      @media (max-width: 768px) {
        .products__item-first:nth-child(n+2), .products__item:nth-child(n+2) {
          margin-top: 0.75em; } }
      @media (max-width: 480px) {
        .products__item-first:nth-child(n+2), .products__item:nth-child(n+2) {
          margin-top: 0; } }
  .products__item-first {
    display: none; }
    @media (max-width: 360px) {
      .products__item-first {
        order: -1;
        margin-bottom: 1em;
        display: block; } }
  @media (max-width: 360px) {
    .products__item:first-child {
      display: none; } }
  .products__leads {
    max-width: 51.875em; }
    @media (max-width: 1280px) {
      .products__leads {
        max-width: 29.25em; } }
    @media (max-width: 1000px) {
      .products__leads {
        min-width: 20.625em; } }
    @media (max-width: 768px) {
      .products__leads {
        min-width: 16.25em; } }
    @media (max-width: 360px) {
      .products__leads {
        order: -1;
        margin-bottom: 2.25em; } }

.products-decor {
  position: absolute;
  background-color: #0C60E5; }
  .products-decor__line {
    top: 22.625em;
    left: 0;
    width: 28%;
    height: 0.125em; }
    @media (max-width: 1280px) {
      .products-decor__line {
        top: 17.5em;
        width: 51%; } }
    @media (max-width: 768px) {
      .products-decor__line {
        top: 10em;
        width: 80%; } }
    @media (max-width: 480px) {
      .products-decor__line {
        top: 11.875em;
        width: 45%; } }

.product__text {
  margin-left: 1.25em;
  font-size: 1.5em;
  font-weight: 500;
  line-height: 1.20833em; }
  @media (max-width: 1280px) {
    .product__text {
      font-size: 1.125em;
      line-height: 1.16667em; } }
  @media (max-width: 1000px) {
    .product__text {
      font-size: 0.75em;
      line-height: 1.25em; } }
  @media (max-width: 768px) {
    .product__text {
      font-size: 0.625em;
      line-height: 1.1em; } }

.product__icon {
  display: flex;
  align-items: center;
  font-size: 3.75em; }
  @media (max-width: 1280px) {
    .product__icon {
      font-size: 3.125em; } }
  @media (max-width: 1000px) {
    .product__icon {
      font-size: 2.5em; } }
  @media (max-width: 768px) {
    .product__icon {
      font-size: 1.25em; } }

.product__link {
  display: flex;
  align-items: center;
  padding: 0.3125em 1.5625em; }
  @media (max-width: 1000px) {
    .product__link {
      padding-left: 0.8125em; } }
  @media (max-width: 768px) {
    .product__link {
      padding: 0.5625em 0.8125em; } }
  .product__link:hover {
    background-color: #FFFFFF;
    color: #000000; }

.leads__title {
  margin-bottom: 1.04167em;
  color: #0C60E5;
  font-size: 3em;
  font-weight: 500;
  line-height: 1.16667em; }
  @media (max-width: 1280px) {
    .leads__title {
      margin-bottom: 0.83333em;
      font-size: 2.25em;
      line-height: 1.16667em; } }
  @media (max-width: 768px) {
    .leads__title {
      margin-bottom: 1.38889em;
      font-size: 1.125em;
      line-height: 1.16667em; } }

.leads__item:nth-child(n+2) {
  margin-top: 1.875em; }
  @media (max-width: 768px) {
    .leads__item:nth-child(n+2) {
      margin-top: 0.625em; } }

.leads__text {
  position: relative;
  padding-left: 0.83333em;
  font-size: 1.5em;
  line-height: 1.125em; }
  @media (max-width: 1280px) {
    .leads__text {
      margin-bottom: 1.38889em;
      font-size: 1.125em;
      line-height: 1.16667em; }
      .leads__text br {
        display: none; } }
  @media (max-width: 768px) {
    .leads__text {
      margin-bottom: 0.83333em;
      font-size: 0.75em;
      line-height: 1.16667em; } }
  .leads__text::before {
    position: absolute;
    top: 1.16667em;
    left: 0;
    width: 0.83333em;
    height: 1px;
    content: '';
    display: inline-block;
    background: currentColor; }
    @media (max-width: 1280px) {
      .leads__text::before {
        top: 0.83333em; } }
    @media (max-width: 1000px) {
      .leads__text::before {
        width: 0.66667em; } }
    @media (max-width: 768px) {
      .leads__text::before {
        width: 0.33333em; } }

.affiliates {
  padding-top: 9.125em;
  padding-bottom: 3.125em; }
  @media (max-width: 1280px) {
    .affiliates {
      padding-top: 8.4375em;
      padding-bottom: 8.4375em; } }
  @media (max-width: 1000px) {
    .affiliates {
      padding-top: 2.4375em;
      padding-bottom: 18.125em; } }
  @media (max-width: 768px) {
    .affiliates {
      padding-top: 3.5625em; } }
  @media (max-width: 480px) {
    .affiliates {
      padding-top: 1.625em; } }
  .affiliates__container {
    margin-bottom: -10.625em; }
    @media (max-width: 1000px) {
      .affiliates__container {
        margin-bottom: -28.75em; } }
    @media (max-width: 1000px) {
      .affiliates__container {
        margin-bottom: -24.6875em; } }
  .affiliates__title {
    max-width: 11.71875em;
    font-size: 4em;
    font-weight: 700;
    line-height: 1.20313em; }
    @media (max-width: 1600px) {
      .affiliates__title {
        max-width: 7.8125em; } }
    @media (max-width: 1280px) {
      .affiliates__title {
        max-width: 7.3125em;
        font-size: 3em;
        line-height: 1.20833em; } }
    @media (max-width: 1000px) {
      .affiliates__title {
        max-width: none; }
        .affiliates__title br {
          display: none; } }
    @media (max-width: 768px) {
      .affiliates__title {
        max-width: 8.61111em;
        font-size: 2.25em;
        line-height: 1.19444em; } }
    @media (max-width: 480px) {
      .affiliates__title {
        max-width: 100%;
        text-align: center; } }
    @media (max-width: 360px) {
      .affiliates__title {
        max-width: 5.69444em;
        text-align: left; } }
  .affiliates__items {
    position: relative;
    z-index: 2;
    max-width: 49.25em;
    min-height: 44.6875em;
    margin-top: -27.3125em;
    margin-left: auto;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(3, 9.0625em);
    column-gap: 4.375em;
    padding: 10em 7.25em;
    background-color: #0C60E5;
    color: #FFFFFF;
    row-gap: 2.8125em; }
    @media (max-width: 1280px) {
      .affiliates__items {
        max-width: 34.375em;
        min-height: 31.25em;
        margin-top: -20.75em;
        grid-template-columns: repeat(3, 7.3125em);
        column-gap: 3.125em;
        padding: 6.25em 3.125em;
        row-gap: 2.5em; } }
    @media (max-width: 1000px) {
      .affiliates__items {
        max-width: 31.4375em;
        min-height: 24.1875em;
        margin-top: 3.25em;
        column-gap: 1.875em;
        padding: 2.8125em 2.5em; } }
    @media (max-width: 768px) {
      .affiliates__items {
        max-width: 15.6875em;
        min-height: 24.1875em;
        margin-top: 1.25em;
        grid-template-columns: repeat(2, 3.75em);
        column-gap: 2.375em;
        padding: 2.1875em 2.9375em;
        row-gap: 1.5625em; } }
    @media (max-width: 480px) {
      .affiliates__items {
        max-width: 17.5em;
        margin-right: auto;
        padding: 1.875em 3.75em; } }
    @media (max-width: 360px) {
      .affiliates__items {
        position: relative;
        left: 50%;
        width: 100vw;
        max-width: 100vw;
        margin-right: 0;
        column-gap: 3.875em;
        transform: translateX(-50%); } }
  .affiliates__item {
    display: flex;
    flex-direction: column;
    align-items: center; }

.affiliat__icon {
  font-size: 7.1875em; }
  @media (max-width: 1280px) {
    .affiliat__icon {
      font-size: 5.0625em; } }
  @media (max-width: 768px) {
    .affiliat__icon {
      font-size: 3.75em; } }

.affiliat__text {
  text-align: center;
  font-size: 1.5em;
  line-height: 1.16667em; }
  @media (max-width: 1280px) {
    .affiliat__text {
      font-size: 1.125em;
      line-height: 1.11111em; } }
  @media (max-width: 768px) {
    .affiliat__text {
      font-size: 0.75em;
      line-height: 1.16667em; } }

.affiliates-decor {
  position: absolute;
  background-color: #0C60E5; }
  .affiliates-decor__line {
    bottom: 14.4375em;
    left: 0;
    width: 87%;
    height: 0.125em;
    transform: translateX(-100%); }
    @media (max-width: 1600px) {
      .affiliates-decor__line {
        bottom: 9.375em; } }
    @media (max-width: 1280px) {
      .affiliates-decor__line {
        bottom: 4.375em;
        width: 54%; } }
    @media (max-width: 1000px) {
      .affiliates-decor__line {
        display: none; } }
  .affiliates-decor__1 {
    top: -4.0625em;
    right: -4.0625em;
    width: 8.125em;
    height: 8.125em;
    background-color: #FFFFFF; }
    @media (max-width: 1780px) {
      .affiliates-decor__1 {
        top: -0.625em;
        right: -0.625em;
        width: 3.0625em;
        height: 3.0625em; } }
    @media (max-width: 1280px) {
      .affiliates-decor__1 {
        top: -2.1875em;
        right: -2.1875em; } }
    @media (max-width: 1000px) {
      .affiliates-decor__1 {
        top: unset;
        bottom: -2.1875em;
        left: -2.1875em; } }
    @media (max-width: 768px) {
      .affiliates-decor__1 {
        right: -1.5em;
        bottom: -1.1875em;
        left: unset; } }
    @media (max-width: 360px) {
      .affiliates-decor__1 {
        right: 1.5em;
        width: 2.5em;
        height: 2.5em; } }
  @media (max-width: 768px) {
    .affiliates-decor__2 {
      top: 6.875em;
      right: unset;
      left: -11.875em;
      width: 3.0625em;
      height: 3.0625em;
      background-color: #0C60E5; } }
  @media (max-width: 480px) {
    .affiliates-decor__2 {
      top: -8.625em;
      right: 0em;
      left: unset;
      width: 1.4375em;
      height: 1.4375em; } }
  @media (max-width: 360px) {
    .affiliates-decor__2 {
      top: -14.0625em; } }
  @media (max-width: 768px) {
    .affiliates-decor__3 {
      top: 2.75em;
      right: unset;
      left: -4.5em;
      width: 1.1875em;
      height: 1.1875em;
      background-color: #0C60E5; } }
  @media (max-width: 480px) {
    .affiliates-decor__3 {
      top: 6.375em;
      left: -3.25em; } }
  @media (max-width: 360px) {
    .affiliates-decor__3 {
      top: -3.625em;
      left: 15.625em; } }

.advantages {
  position: relative;
  z-index: 1;
  padding-top: 21.25em;
  padding-bottom: 13.75em;
  background-color: #000000;
  color: #FFFFFF; }
  @media (max-width: 1280px) {
    .advantages {
      padding-top: 10em;
      padding-bottom: 10em; } }
  @media (max-width: 1000px) {
    .advantages {
      padding-top: 17.6875em;
      padding-bottom: 9.375em; } }
  @media (max-width: 768px) {
    .advantages {
      padding-top: 10.625em;
      padding-bottom: 5em; } }
  @media (max-width: 480px) {
    .advantages {
      padding-top: 11.25em; } }
  .advantages__container {
    max-width: 83.125em; }
    @media (max-width: 1280px) {
      .advantages__container {
        max-width: 59.75em; } }
    @media (max-width: 1000px) {
      .advantages__container {
        max-width: 36.875em; } }
    @media (max-width: 768px) {
      .advantages__container {
        max-width: 29.25em; } }
    @media (max-width: 480px) {
      .advantages__container {
        max-width: 21.25em; } }
  .advantages__title {
    margin-bottom: 1.875em;
    text-align: center;
    font-size: 4em;
    font-weight: 700;
    line-height: 1.20313em; }
    @media (max-width: 1280px) {
      .advantages__title {
        margin-bottom: 2.08333em;
        font-size: 3em;
        line-height: 1.20833em; } }
    @media (max-width: 768px) {
      .advantages__title {
        margin-bottom: 1.66667em;
        font-size: 2.25em;
        line-height: 1.19444em; } }
  .advantages__list {
    display: flex; }
    @media (max-width: 1000px) {
      .advantages__list {
        flex-direction: column;
        align-items: center; } }
  .advantages__items:nth-child(even) {
    margin-left: 21.125em; }
    @media (max-width: 1280px) {
      .advantages__items:nth-child(even) {
        margin-left: 1.25em; } }
    @media (max-width: 1000px) {
      .advantages__items:nth-child(even) {
        margin-left: 0; } }
  .advantages__item {
    max-width: 30em;
    margin-bottom: 3.125em;
    display: flex; }
    @media (max-width: 1280px) {
      .advantages__item {
        margin-bottom: 2.5em; } }
    @media (max-width: 1000px) {
      .advantages__item {
        max-width: 25em; } }
    @media (max-width: 768px) {
      .advantages__item {
        max-width: 22.8125em; } }
    @media (max-width: 480px) {
      .advantages__item {
        max-width: 17.8125em; } }

.advantage__text {
  margin-left: 1.125em;
  font-size: 1.5em;
  line-height: 1.125em; }
  @media (max-width: 1280px) {
    .advantage__text {
      font-size: 1.125em;
      line-height: 1.11111em; } }
  @media (max-width: 768px) {
    .advantage__text {
      font-size: 0.875em;
      line-height: 1.14286em; } }
  .advantage__text--header {
    margin-bottom: 1.42857em; }
    @media (max-width: 1280px) {
      .advantage__text--header {
        margin-bottom: 1.07143em; } }

.checkmark {
  display: inline-block; }
  .checkmark::after {
    width: 1em;
    height: 2em;
    content: '';
    display: block;
    border: solid #0C60E5;
    border-width: 0em 0.25em 0.25em 0em;
    transform: rotate(45deg); }
    @media (max-width: 1280px) {
      .checkmark::after {
        width: 0.75em;
        height: 1.375em;
        border-width: 0em 0.1875em 0.1875em 0em; } }
    @media (max-width: 768px) {
      .checkmark::after {
        margin-top: -0.625em; } }
    @media (max-width: 480px) {
      .checkmark::after {
        margin-top: -0.3125em; } }

.advertisers {
  position: relative;
  min-height: 50em;
  padding-top: 11.875em;
  padding-bottom: 6.25em;
  background: url("/img/advertisers-bg.jpg") center center/cover no-repeat, #000000;
  color: #FFFFFF; }
  @media (max-width: 1280px) {
    .advertisers {
      padding-top: 4.9375em; } }
  @media (max-width: 768px) {
    .advertisers {
      min-height: 46.875em;
      padding-bottom: 0;
      background-position: top;
      background-size: auto 21.875em; } }
  @media (max-width: 480px) {
    .advertisers {
      padding-top: 3.3125em; } }
  @media (max-width: 360px) {
    .advertisers {
      background-size: auto 25em; } }
  .advertisers::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    display: block;
    background-image: linear-gradient(90.12deg, rgba(0, 0, 0, 0.8) 110.81%, rgba(0, 0, 0, 0) 145.19%); }
  .advertisers__container {
    margin-bottom: -31.25em;
    display: flex; }
    @media (max-width: 1000px) {
      .advertisers__container {
        margin-top: 1.25em;
        flex-direction: column;
        padding: 0; } }
    @media (max-width: 768px) {
      .advertisers__container {
        margin-bottom: 0; } }
  .advertisers__header {
    position: relative;
    max-width: 45.625em;
    margin-right: 5em;
    padding-bottom: 3.125em; }
    @media (max-width: 1625px) {
      .advertisers__header {
        max-width: 33.125em;
        margin-right: 0; } }
    @media (max-width: 768px) {
      .advertisers__header {
        width: 100%;
        max-width: 100%; } }
    @media (max-width: 480px) {
      .advertisers__header {
        margin-left: 0;
        padding-right: 0.9375em;
        padding-left: 0.9375em; } }
  .advertisers__title {
    margin-bottom: 0.46875em;
    font-size: 4em;
    font-weight: 700;
    line-height: 1.17188em; }
    @media (max-width: 1625px) {
      .advertisers__title {
        font-size: 3em;
        line-height: 1.16667em; } }
    @media (max-width: 768px) {
      .advertisers__title {
        font-size: 2.25em;
        line-height: 1.16667em; } }
  .advertisers__subtitle {
    font-size: 1.5em;
    line-height: 1.16667em; }
    @media (max-width: 1625px) {
      .advertisers__subtitle {
        font-size: 1.125em;
        line-height: 1.11111em; } }
    @media (max-width: 768px) {
      .advertisers__subtitle {
        font-size: 0.875em;
        line-height: 1.14286em; } }
  .advertisers__form {
    width: 100%;
    max-width: 56.25em;
    min-height: 96.25em;
    margin-top: -39.25em;
    margin-right: -6.875em;
    padding: 41.875em 16.25em 32.8125em 8em;
    transform: rotate(21deg); }
    @media (max-width: 1625px) {
      .advertisers__form {
        max-width: 45em;
        padding-right: 6.875em; } }
    @media (max-width: 1280px) {
      .advertisers__form {
        max-width: 41.5625em;
        margin-top: -26.25em;
        margin-right: -10em;
        padding: 39.125em 8.4375em 32.8125em 5.3125em;
        transform: rotate(27deg); } }
    @media (max-width: 1200px) {
      .advertisers__form {
        right: -7.5em;
        padding-right: 12.5em; } }
    @media (max-width: 1000px) {
      .advertisers__form {
        max-width: 45.125em;
        min-height: 24.375em;
        margin-top: auto;
        margin-left: auto;
        padding: 2.25em 12.8125em 1.75em 3.625em;
        transform: rotate(0deg); } }
    @media (max-width: 768px) {
      .advertisers__form {
        position: relative;
        left: 50%;
        width: 100vw;
        max-width: 100vw;
        margin-left: 0;
        padding-right: 0;
        padding-left: 0;
        transform: translateX(-50%); } }
    @media (max-width: 360px) {
      .advertisers__form {
        padding-right: 1.25em;
        padding-left: 1.25em; } }

.form-advertisers {
  background-color: #FFFFFF;
  color: #000000; }
  .form-advertisers__content {
    transform: rotate(-21deg); }
    @media (max-width: 1280px) {
      .form-advertisers__content {
        transform: rotate(-26deg); } }
    @media (max-width: 1000px) {
      .form-advertisers__content {
        transform: rotate(0deg); } }
    @media (max-width: 768px) {
      .form-advertisers__content {
        max-width: 27.75em;
        margin-right: auto;
        margin-left: auto; } }
    @media (max-width: 480px) {
      .form-advertisers__content {
        max-width: 21.25em; } }
  .form-advertisers__header {
    max-width: 25em; }
    @media (max-width: 1280px) {
      .form-advertisers__header {
        max-width: 20em; } }
  .form-advertisers__title {
    margin-bottom: 1.66667em;
    color: #0C60E5;
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1.16667em; }
    @media (max-width: 1280px) {
      .form-advertisers__title {
        margin-bottom: 1.66667em;
        font-size: 1.125em;
        line-height: 1.16667em; } }
  .form-advertisers__subtitle {
    margin-bottom: 1.66667em;
    font-size: 1.125em;
    line-height: 1.16667em; }
    @media (max-width: 1280px) {
      .form-advertisers__subtitle {
        font-size: 0.875em;
        line-height: 1.14286em; } }
  .form-advertisers__input {
    width: 100%;
    min-height: 3.42857em;
    padding: 0.71429em 1.14286em;
    border: 1px solid rgba(0, 0, 0, 0.15);
    color: #000000;
    font-size: 0.875em;
    line-height: 1.14286em; }
    .form-advertisers__input::placeholder {
      color: inherit; }
  .form-advertisers__items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.25em; }
    @media (max-width: 480px) {
      .form-advertisers__items {
        grid-template-columns: 1fr; } }
  .form-advertisers__item {
    position: relative; }
    .form-advertisers__item--required::after {
      position: absolute;
      top: 0.71429em;
      right: 0.57143em;
      content: '*';
      color: #0C60E5;
      font-size: 0.875em; }
  .form-advertisers__fieldset {
    margin-bottom: 0.9375em; }
    @media (max-width: 1000px) {
      .form-advertisers__fieldset:nth-last-child(1) {
        margin-bottom: 0; } }
  .form-advertisers__textarea {
    min-height: 5em;
    margin-bottom: 0.9375em; }
  .form-advertisers__btns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.25em;
    margin-top: 0.9375em; }
    @media (max-width: 360px) {
      .form-advertisers__btns {
        grid-template-columns: 1fr;
        row-gap: 2.1875em; } }

.advertisers-decor {
  position: absolute;
  background-color: #FFFFFF; }
  .advertisers-decor__line {
    top: -1.1875em;
    right: 0;
    z-index: 1;
    width: 71%;
    height: 1.25em; }
    @media (max-width: 1280px) {
      .advertisers-decor__line {
        width: 52%; } }
    @media (max-width: 480px) {
      .advertisers-decor__line {
        width: 20%; } }
    @media (max-width: 360px) {
      .advertisers-decor__line {
        top: -0.5em;
        width: 70%;
        height: 0.4375em; } }

.careers {
  position: relative;
  z-index: 1;
  min-height: 74.375em;
  padding-top: 13.4375em;
  padding-bottom: 3.125em;
  background-color: #000000;
  color: #FFFFFF; }
  @media (max-width: 1280px) {
    .careers {
      min-height: 69.6875em;
      padding-top: 10.125em; } }
  @media (max-width: 768px) {
    .careers {
      padding-top: 4.625em; } }
  @media (max-width: 480px) {
    .careers {
      padding-top: 4.4375em; } }
  .careers__title {
    margin-bottom: 0.78125em;
    font-size: 4em;
    font-weight: 700;
    line-height: 1.17188em; }
    @media (max-width: 1280px) {
      .careers__title {
        margin-bottom: 0.625em;
        font-size: 3em;
        line-height: 1.16667em; } }
    @media (max-width: 768px) {
      .careers__title {
        margin-bottom: 0.69444em;
        font-size: 2.25em;
        line-height: 1.16667em; } }
  .careers__header {
    margin-bottom: 4.6875em;
    display: flex; }
    @media (max-width: 1280px) {
      .careers__header {
        margin-bottom: 7.875em; } }
    @media (max-width: 1000px) {
      .careers__header {
        margin-bottom: 4.6875em;
        flex-direction: column; } }
    @media (max-width: 768px) {
      .careers__header {
        margin-bottom: 5.375em; } }
    @media (max-width: 480px) {
      .careers__header {
        margin-bottom: 3.5em; } }
  .careers__text {
    color: #0C60E5;
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1.16667em; }
    @media (max-width: 1280px) {
      .careers__text {
        font-size: 1.125em;
        line-height: 1.16667em; } }
    @media (max-width: 768px) {
      .careers__text {
        font-size: 0.875em;
        line-height: 1.14286em; } }
  .careers__email {
    margin-right: 5.83333em;
    margin-left: auto;
    font-size: 1.5em;
    font-weight: 400;
    line-height: 1.16667em; }
    @media (max-width: 1280px) {
      .careers__email {
        margin-right: 0;
        font-size: 1.125em;
        line-height: 1.16667em; } }
    @media (max-width: 1000px) {
      .careers__email {
        margin-top: 1.66667em;
        margin-left: 0; } }
    @media (max-width: 768px) {
      .careers__email {
        font-size: 0.875em;
        line-height: 1.14286em; } }
    @media (max-width: 480px) {
      .careers__email {
        margin-top: 1.07143em; } }
  .careers__content {
    display: flex; }
    @media (max-width: 1000px) {
      .careers__content {
        flex-direction: column;
        align-items: flex-start; } }
  .careers__subtitle {
    margin-bottom: 0.91667em;
    font-size: 2.25em;
    font-weight: 500;
    line-height: 1.11111em; }
    @media (max-width: 1280px) {
      .careers__subtitle {
        margin-bottom: 1.875em;
        font-size: 1.5em;
        line-height: 1.08333em; } }
    @media (max-width: 768px) {
      .careers__subtitle {
        margin-bottom: 1.38889em;
        font-size: 1.125em;
        line-height: 1.11111em; } }
  @media (max-width: 480px) {
    .careers__jobs {
      position: relative;
      left: 50%;
      width: 100vw;
      max-width: 100vw;
      transform: translateX(-50%); } }
  @media (max-width: 360px) {
    .careers__jobs {
      left: 29%;
      width: 66vw; } }

.careers-decor {
  position: absolute;
  background-color: #0C60E5; }
  .careers-decor__line {
    top: 13.3125em;
    right: 0;
    width: 56%;
    height: 0.125em; }
    @media (max-width: 1280px) {
      .careers-decor__line {
        top: 10em;
        width: 35%; } }
    @media (max-width: 768px) {
      .careers-decor__line {
        top: 5em;
        width: 30%; } }
    @media (max-width: 480px) {
      .careers-decor__line {
        top: 4.375em;
        width: 50%; } }

.careers-link__caption {
  color: #0C60E5; }

.jobs {
  height: 100%;
  background-color: #FFFFFF;
  color: #000000; }
  .jobs__form {
    min-width: 18.75em;
    min-height: 28.75em;
    padding: 2.5em; }
    @media (max-width: 1000px) {
      .jobs__form {
        min-width: 31.375em;
        min-height: 17.5em;
        display: flex;
        flex-wrap: wrap; } }
    @media (max-width: 768px) {
      .jobs__form {
        min-width: 27.75em;
        min-height: 17.25em; } }
    @media (max-width: 480px) {
      .jobs__form {
        min-width: 100%;
        margin-right: auto;
        margin-left: auto;
        justify-content: center;
        padding-right: 0;
        padding-left: 0; } }
    @media (max-width: 360px) {
      .jobs__form {
        flex-direction: column;
        padding-left: 1.375em; } }

.form-jobs__legend {
  margin-bottom: 1.875em;
  display: flex;
  align-items: center; }

.form-jobs__legend-text {
  margin-left: 0.44444em;
  font-size: 1.125em;
  line-height: 1.66667em; }
  @media (max-width: 768px) {
    .form-jobs__legend-text {
      font-size: 0.875em;
      line-height: 1.64286em; } }

.form-jobs__icon {
  color: #0C60E5;
  font-size: 1.375em; }
  @media (max-width: 768px) {
    .form-jobs__icon {
      font-size: 1.25em; } }

.form-jobs__fieldset:nth-child(n+2) {
  margin-top: 3.75em; }
  @media (max-width: 1000px) {
    .form-jobs__fieldset:nth-child(n+2) {
      margin-top: 0;
      margin-left: 5.625em; } }
  @media (max-width: 768px) {
    .form-jobs__fieldset:nth-child(n+2) {
      margin-left: 3.4375em; } }
  @media (max-width: 360px) {
    .form-jobs__fieldset:nth-child(n+2) {
      margin-top: 2.5em;
      margin-left: 0; } }

@media (max-width: 1000px) {
  .form-jobs__fieldset--select {
    width: 100%;
    max-width: 13.25em; } }

@media (max-width: 768px) {
  .form-jobs__fieldset--select {
    max-width: 13.125em; } }

@media (max-width: 480px) {
  .form-jobs__fieldset--select {
    max-width: 10em; } }

.form-jobs__item:nth-child(n+2) {
  margin-top: 1.875em; }
  @media (max-width: 768px) {
    .form-jobs__item:nth-child(n+2) {
      margin-top: 1.25em; } }

.form-jobs-item__input {
  position: absolute;
  appearance: none; }
  .form-jobs-item__input:focus + .form-jobs-item__box {
    outline: 1px solid #0C60E5; }
  .form-jobs-item__input + .form-jobs-item__box {
    background-image: url(/img/check.svg); }
  .form-jobs-item__input:checked + .form-jobs-item__box {
    background-image: url(/img/check-check.svg); }

.form-jobs-item__box {
  position: absolute;
  width: 1.5em;
  height: 1.5em;
  margin-left: -2.5em; }

.form-jobs-item__label {
  margin-left: 2.5em;
  display: flex;
  align-items: center; }

.form-jobs-item__text {
  font-size: 0.875em;
  line-height: 1.64286em; }

.vacancies__items {
  margin-left: 6.5625em;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 9.6875em;
  row-gap: 3.9375em; }
  @media (max-width: 1280px) {
    .vacancies__items {
      grid-template-columns: 1fr;
      row-gap: 5em; } }
  @media (max-width: 1000px) {
    .vacancies__items {
      margin-top: 4.125em;
      margin-left: 0; } }
  @media (max-width: 768px) {
    .vacancies__items {
      margin-top: 3.75em; } }

.vacancies__item {
  padding-bottom: 1em;
  border-bottom: 1px solid #0C60E5; }
  @media (max-width: 1000px) {
    .vacancies__item {
      max-width: 34.375em; } }
  @media (max-width: 768px) {
    .vacancies__item {
      max-width: 27.75em; } }

.vacancy__title {
  margin-bottom: 0.3125em;
  font-size: 2em;
  font-weight: 500;
  line-height: 1.25em; }
  @media (max-width: 1280px) {
    .vacancy__title {
      margin-bottom: 0.58333em;
      font-size: 1.5em;
      line-height: 1.25em; } }

.vacancy__icon {
  color: #0C60E5;
  font-size: 1.25em; }

.vacancy__location {
  margin-left: 0.57143em;
  font-size: 0.875em;
  line-height: 1.21429em; }

.vacancy__locality {
  margin-bottom: 1.9375em;
  display: flex;
  align-items: center; }

.vacancy__description {
  font-size: 0.875em;
  line-height: 1.21429em; }

.footer {
  padding-top: 0.3125em;
  padding-bottom: 0.3125em;
  background-color: #000000;
  color: #FFFFFF; }
  @media (max-width: 768px) {
    .footer {
      padding-bottom: 1.6875em; } }
  .footer__container {
    display: flex;
    align-items: center; }
    @media (max-width: 480px) {
      .footer__container {
        flex-direction: column; } }
  .footer__nav {
    margin-right: auto;
    margin-left: auto; }
    @media (max-width: 480px) {
      .footer__nav {
        order: -1;
        margin-top: 1.25em;
        margin-bottom: 1.25em; } }
  .footer__logo {
    flex: none; }
    @media (max-width: 480px) {
      .footer__logo {
        margin-bottom: 1.25em; } }
  @media (max-width: 768px) {
    .footer__nav-list {
      flex-direction: column;
      align-items: center; } }
  @media (max-width: 768px) {
    .footer__nav-item:nth-child(n+2) {
      margin-top: 0.625em;
      margin-left: 0; } }

.footer-nav__link {
  min-height: 6.25em; }
  @media (max-width: 1280px) {
    .footer-nav__link {
      min-height: 5.57143em;
      font-size: 0.875em;
      line-height: 1.07143em; } }
  @media (max-width: 1000px) {
    .footer-nav__link {
      font-size: 0.5625em;
      line-height: 1.22222em; } }
  @media (max-width: 768px) {
    .footer-nav__link {
      min-height: 0.625em; } }

.social-network {
  display: flex;
  align-items: center; }
  .social-network__item {
    color: #0C60E5; }
    .social-network__item:nth-child(n+2) {
      margin-left: 1.75em; }
      @media (max-width: 1000px) {
        .social-network__item:nth-child(n+2) {
          margin-left: 1.125em; } }
  .social-network__icon {
    font-size: 1.375em; }
    @media (max-width: 1000px) {
      .social-network__icon {
        font-size: 1.0625em; } }
  .social-network__link:hover {
    color: #FFFFFF;
    stroke: #0C60E5; }

/* ======================================
  Selectric v1.13.0
====================================== */
/* Items box */
.s-selectric .selectric-wrapper {
  position: relative;
  cursor: pointer; }

.s-selectric .selectric-responsive {
  width: 100%; }

.s-selectric .selectric {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.8125em 1.25em;
  overflow: hidden;
  background-color: transparent;
  color: #000000;
  outline: 1px solid rgba(0, 0, 0, 0.15);
  outline-offset: -1px; }
  @media (max-width: 768px) {
    .s-selectric .selectric {
      padding-right: 0.9375em; } }
  .s-selectric .selectric:hover, .s-selectric .selectric:focus {
    background: linear-gradient(250deg, #FFFFFF 0%, #0C60E5 100%), transparent;
    outline: none; }
  .s-selectric .selectric:focus {
    outline: none;
    border: 0; }
    .s-selectric .selectric:focus::placeholder {
      color: inherit; }
  .s-selectric .selectric .label {
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Gilroy", sans-serif;
    font-size: 1.125em;
    text-transform: uppercase;
    line-height: 1.66667em;
    white-space: nowrap;
    user-select: none; }
    @media (max-width: 768px) {
      .s-selectric .selectric .label {
        font-size: 0.875em;
        line-height: 1.78571em; } }
    @media (max-width: 480px) {
      .s-selectric .selectric .label {
        font-size: 0.75em;
        line-height: 1.58333em; } }
  .s-selectric .selectric .button {
    position: absolute;
    top: 0;
    right: 0;
    width: 3.125em;
    height: 100%;
    opacity: 0;
    display: block;
    color: #000000;
    text-align: center; }
  .s-selectric .selectric::after {
    width: 0.75em;
    height: 0.5em;
    margin-left: auto;
    content: '';
    background: url("../../img/arrow.svg") center center/cover no-repeat;
    transition: all 0.4s; }
    @media (max-width: 768px) {
      .s-selectric .selectric::after {
        width: 0.625em;
        height: 0.375em; } }

.s-selectric .selectric-open {
  z-index: 9999; }
  .s-selectric .selectric-open .selectric::after {
    transform: rotate(180deg); }
  .s-selectric .selectric-open .selectric-items {
    display: block;
    background: #FFFFFF; }

.s-selectric .selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none; }

.s-selectric .selectric-hide-select {
  position: relative;
  width: 0;
  height: 0;
  overflow: hidden; }
  .s-selectric .selectric-hide-select select {
    position: absolute;
    left: -100%; }

.s-selectric .selectric-hide-select.selectric-is-native {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%; }
  .s-selectric .selectric-hide-select.selectric-is-native select {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
    box-sizing: border-box;
    border: 0; }

.s-selectric .selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  outline: none !important;
  border: 0 !important;
  background: none !important;
  clip: rect(0, 0, 0, 0) !important; }

.s-selectric .selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important; }

.s-selectric .selectric-items {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: -1;
  width: 100%;
  display: none;
  border-radius: 0.3125em; }
  .s-selectric .selectric-items ::-webkit-scrollbar-track {
    margin-top: 0.625em;
    margin-bottom: 0.4375em;
    background: transparent; }
  .s-selectric .selectric-items ::-webkit-scrollbar-thumb {
    background: #ADBFCD; }
  .s-selectric .selectric-items ::-webkit-resizer {
    width: 0.25em;
    height: 0em;
    background-image: none;
    background-repeat: no-repeat; }
  .s-selectric .selectric-items ::-webkit-scrollbar {
    width: 0.25em; }
  .s-selectric .selectric-items .selectric-scroll {
    height: 100%;
    max-height: 8.75em;
    margin-right: 0.25em;
    padding-top: 0.3125em;
    padding-bottom: 0.3125em;
    overflow: auto; }
  .s-selectric .selectric-items li {
    min-height: 1.875em;
    margin-right: 0.1875em;
    margin-left: 0.75em;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0.4375em 0.625em 0.4375em 0.5625em;
    border-radius: 0.625em;
    color: #000000; }
    .s-selectric .selectric-items li:nth-child(n+2) {
      margin-top: 0.125em; }
    .s-selectric .selectric-items li:hover {
      background: linear-gradient(250deg, #FFFFFF 0%, #0C60E5 100%), transparent;
      color: #FFFFFF; }
  .s-selectric .selectric-items .selected {
    display: none; }
  .s-selectric .selectric-items .highlighted {
    background: linear-gradient(270deg, #FF8404 0%, #0C60E5 100%), #FFB930;
    color: #FFFFFF; }
  .s-selectric .selectric-items .disabled {
    opacity: 0.5;
    cursor: default !important;
    background: none !important;
    color: #666 !important;
    filter: alpha(opacity=50);
    user-select: none; }
  .s-selectric .selectric-items .selectric-group .selectric-group-label {
    cursor: default;
    padding-left: 0.625em;
    background: none;
    color: black;
    user-select: none; }
  .s-selectric .selectric-items .selectric-group li {
    padding-left: 25px; }
  .s-selectric .selectric-items .selectric-group.disabled li {
    opacity: 1;
    filter: alpha(opacity=100); }

.s-selectric .selectric-above .selectric-items {
  top: auto;
  bottom: 100%; }
