html {
  font-size: #{$fz_base}px;
}

body {
  min-width: em(320, $fz);

  display: flex;
  background-color: $white;
  color: $black;
  font-family: $font_family;
}

.main {
  overflow: hidden;
}

.footer {
  margin-top: auto;
}

.container {
  width: 100%;
  max-width: em(1630, $fz);
  margin-right: auto;
  margin-left: auto;

  padding-right: em(15, $fz);
  padding-left: em(15, $fz);

  @media (max-width: $media_extra_large) {
    max-width: em(980, $fz);
  }

  @media (max-width: $media_large) {
    max-width: em(677, $fz);
  }

  @media (max-width: $media_medium) {
    max-width: em(473, $fz);
  }

  @media (max-width: $media_small) {
    max-width: em(370, $fz);
  }

  @media (max-width: $media_extra_small) {
    max-width: em(310, $fz);
  }
}

.btn {
  $self: &;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: em(5 15, $fz);

  &--size-l {
    min-width: em(250, $fz);
    min-height: em(62, $fz);

    @media (max-width: $media_extra_large) {
      min-width: em(224, $fz);
      min-height: em(56, $fz);
    }

    @media (max-width: $media_medium) {
      min-width: em(212, $fz);
      min-height: em(53, $fz);
    }

    @media (max-width: $media_small) {
      min-width: em(130, $fz);
      max-height: em(40, $fz);
    }

    @media (max-width: $media_extra_small) {
      justify-self: center;
      max-width: em(160, $fz);
      min-height: em(50, $fz);
    }
  }

  &--size-m {
    min-width: em(384, $fz);
    min-height: em(80, $fz);

    border-radius: em(5, $fz);

    @media (max-width: $media_medium) {
      min-width: em(251, $fz);
      min-height: em(60, $fz);
    }

    @media (max-width: $media_extra_small) {
      width: 100%;
    }
  }

  &__icon {
    $fz: 35;
    font-size: em($fz, $fz_base);

    @media (max-width: $media_small) {
      $fz: 20;
      font-size: em($fz, $fz_base);
    }
  }

  &__caption {
    #{$self}--size-m & {
      $fz: 24;
      margin-right: em(35, $fz);
      text-transform: uppercase;
      font-size: em($fz, $fz_base);
      font-weight: 500;
      line-height: em(27, $fz);

      @media (max-width: $media_medium) {
        $fz: 14;
        font-size: em($fz, $fz_base);
        line-height: em(17, $fz);
      }
    }

    #{$self}--size-l & {
      $fz: 18;
      font-size: em($fz, $fz_base);
      font-weight: 600;
      line-height: em(30, $fz);
    }
  }

  &--theme-accent {
    background: $accent;
    color: $white;

    &:hover,
    &:focus {
      box-shadow: em(0 5 48 7 rgba($accent, 0.5), $fz);
    }
  }

  &--theme-default {
    border: 1px solid $white;
    background: transparent;

    &:hover,
    &:focus {
      box-shadow: em(0 5 48 7 rgba($white, 0.5), $fz);
    }
  }
}

.text {
  &--accent {
    color: $accent;
  }
}

.header {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  background-color: transparent;
  color: $white;

  &:not(&--transparent) {
    background-color: rgba(0, 0, 0, 0.7);
    box-shadow: em(0 4 30 rgba(0, 0, 0, 0.25), $fz);
  }

  &__container {
    display: flex;
    align-items: center;
    letter-spacing: em(2, $fz);

    @media (max-width: $media_medium) {
      flex-direction: column;
      align-items: flex-start;
      padding-top: em(30, $fz);

      @media (max-width: $media_small) {
        flex-direction: row;
        padding-top: em(60, $fz);
        padding-bottom: em(60, $fz);
      }
    }
  }

  &__logo {
    flex: none;
    margin-right: em(15, $fz);
  }

  &__nav {
    margin-left: auto;
  }
}

.logo {
  &__img {
    width: em(160, $fz);
    height: em(26, $fz);

    @media (max-width: $media_extra_large) {
      width: em(143, $fz);
      height: em(23, $fz);
    }

    @media (max-width: $media_large) {
      width: em(96, $fz);
      height: em(16, $fz);
    }
  }
}

.nav {
  &__list {
    flex: auto;

    display: flex;
    flex-wrap: wrap;

    @media (max-width: $media_small) {
      flex: none;
      width: 100%;

      flex-direction: column;
      align-items: end;
    }
  }

  &__item {
    &:nth-child(n+2) {
      margin-left: em(96, $fz);

      @media (max-width: $media_extra_large) {
        margin-left: em(56, $fz);
      }

      @media (max-width: $media_large) {
        margin-left: em(36, $fz);
      }

      @media (max-width: $media_small) {
        margin-top: em(10, $fz);
        margin-left: 0;
      }
    }
  }

  &__link {
    $fz: 14;
    min-height: em(132, $fz);

    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: em($fz, $fz_base);
    font-weight: 500;

    @media (max-width: $media_large) {
      $fz: 12;
      font-size: em($fz, $fz_base);
    }

    @media (max-width: $media_medium) {
      $fz: 9;
      min-height: em(100, $fz);
      font-size: em($fz, $fz_base);
    }

    @media (max-width: $media_small) {
      min-height: em(11, $fz);
    }

    &:hover,
    &:focus {
      color: $accent;
    }
  }

  &__container {
    @media (max-width: $media_small) {
      position: fixed;
      top: 0;
      right: 0;
      width: em(200, $fz);
      min-height: em(258, $fz);

      visibility: hidden;

      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: start;
      padding-top: em(113, $fz);
      padding-right: em(70, $fz);
      padding-bottom: em(40, $fz);
      background-color: rgba(0, 0, 0, 0.5);

      @media (max-width: $media_extra_small) {
        width: em(150, $fz);

        padding-right: em(40, $fz);
      }

      &--is-open {
        visibility: visible;
      }
    }
  }

  &__toggle {
    display: none;

    @media (max-width: $media_small) {
      display: block;
    }
  }
}

.nav-toggle {
  position: relative;
  z-index: 50;
  width: em(30, $fz);
  height: em(19, $fz);
  color: $white;

  &--is-toggled {
    color: $white;
  }

  &__bar {
    position: absolute;
    top: 40%;
    right: 0;
    width: em(30, $fz);
    height: em(3, $fz);
    margin-top: em(-5, $fz);

    border-radius: em(7, $fz);
    background: $white;
    transition: all, 0.2s;
  }

  &__bar + &__bar {
    margin-top: em(2, $fz);
  }

  &__bar + &__bar + &__bar {
    margin-top: em(9, $fz);
  }

  &--is-toggled &__bar {
    transform: rotate(45deg) translateX(em(4, $fz)) translateY(em(1.3, $fz));
  }

  &--is-toggled &__bar + &__bar {
    opacity: 1;
    transform: rotate(-45deg) translateX(em(4, $fz)) translateY(em(-1, $fz));
  }

  &--is-toggled &__bar + &__bar + &__bar {
    opacity: 0;
    transform: scale(0.1);
  }
}

.hero {
  position: relative;
  min-height: em(1087, $fz);

  padding-top: em(380, $fz);
  padding-bottom: em(100, $fz);
  background: url('/img/hero-bg.jpg') center center / cover no-repeat, $black;
  color: $white;
  text-transform: uppercase;

  @media (max-width: $media_extra_large) {
    min-height: em(853, $fz);

    padding-top: em(310, $fz);
    padding-bottom: em(242, $fz);
  }

  @media (max-width: $media_large) {
    padding-top: em(331, $fz);
  }

  @media (max-width: $media_medium) {
    min-height: em(730, $fz);

    padding-top: em(267, $fz);
  }

  @media (max-width: $media_small) {
    min-height: auto;

    padding-top: em(287, $fz);
    padding-bottom: em(135, $fz);
    background-position: 80%;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    content: '';

    display: block;
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.7) -10.38%, rgba(0, 0, 0, 0) 162.79%);
  }

  &__container {
    position: relative;
    letter-spacing: em(2, $fz);
  }

  &__content {
    max-width: em(1080, $fz);

    @media (max-width: $media_extra_large) {
      max-width: em(705, $fz);
    }

    @media (max-width: $media_large) {
      max-width: em(540, $fz);
    }

    @media (max-width: $media_medium) {
      max-width: em(376, $fz);
    }

    @media (max-width: $media_small) {
      max-width: em(293, $fz);
    }
  }

  &__title {
    $fz: 72;
    margin-bottom: em(113, $fz);
    font-size: em($fz, $fz_base);
    font-weight: 700;
    line-height: em(87, $fz);

    @media (max-width: $media_extra_large) {
      $fz: 48;
      margin-bottom: em(60, $fz);
      font-size: em($fz, $fz_base);
      line-height: em(58, $fz);
    }

    @media (max-width: $media_large) {
      $fz: 36;
      font-size: em($fz, $fz_base);
      line-height: em(43, $fz);
    }

    @media (max-width: $media_medium) {
      $fz: 24;
      font-size: em($fz, $fz_base);
      line-height: em(30, $fz);
    }

    @media (max-width: $media_small) {
      $fz: 18;
      font-size: em($fz, $fz_base);
      line-height: em(22, $fz);
    }
  }

  &__mark {
    margin-bottom: em(24, $fz);

    @media (max-width: $media_medium) {
      $fz: 14;
      font-size: em($fz, $fz_base);
      line-height: em(17, $fz);
    }
  }
}

.about {
  position: relative;
  min-height: em(838, $fz);

  padding-top: em(210, $fz);
  padding-bottom: em(50, $fz);

  @media (max-width: $media_large) {
    min-height: em(612, $fz);

    padding-top: em(178, $fz);
  }

  @media (max-width: $media_medium) {
    min-height: em(460, $fz);

    padding-top: em(113, $fz);
  }

  @media (max-width: $media_small) {
    padding-top: em(100, $fz);
  }

  &__container {
    position: relative;
  }

  &__content {
    max-width: em(720, $fz);
    margin-left: 42%;

    @media (max-width: $media_large) {
      max-width: em(547, $fz);
      margin-left: 0;
    }

    @media (max-width: $media_medium) {
      max-width: em(425, $fz);
    }

    @media (max-width: $media_small) {
      max-width: em(320, $fz);
    }

    @media (max-width: $media_extra_small) {
      padding-bottom: em(30, $fz);
    }
  }

  &__title {
    $fz: 64;
    margin-bottom: em(50, $fz);
    font-size: em($fz, $fz_base);
    font-weight: 700;
    line-height: em(77, $fz);

    @media (max-width: $media_extra_large) {
      $fz: 48;
      margin-bottom: em(30, $fz);
      font-size: em($fz, $fz_base);
      line-height: em(58, $fz);
    }

    @media (max-width: $media_medium) {
      $fz: 36;
      margin-bottom: em(25, $fz);
      font-size: em($fz, $fz_base);
      line-height: em(43, $fz);
    }
  }

  &__subtitle {
    $fz: 24;
    margin-bottom: em(30, $fz);
    color: $accent;
    font-size: em($fz, $fz_base);
    font-weight: 500;
    line-height: em(29, $fz);

    @media (max-width: $media_extra_large) {
      $fz: 18;
      margin-bottom: em(20, $fz);
      font-size: em($fz, $fz_base);
      line-height: em(21, $fz);
    }

    @media (max-width: $media_medium) {
      $fz: 14;
      margin-bottom: em(15, $fz);
      font-size: em($fz, $fz_base);
      line-height: em(17, $fz);
    }
  }

  &__text {
    $fz: 24;
    margin-bottom: em(20, $fz);
    font-size: em($fz, $fz_base);
    line-height: em(28, $fz);

    @media (max-width: $media_extra_large) {
      $fz: 18;
      margin-bottom: em(20, $fz);
      font-size: em($fz, $fz_base);
      line-height: em(21, $fz);
    }

    @media (max-width: $media_medium) {
      $fz: 14;
      font-size: em($fz, $fz_base);
      line-height: em(17, $fz);
    }
  }
}

.about-decor {
  position: absolute;
  background-color: $accent;

  &__1 {
    top: em(-56, $fz);
    left: em(78, $fz);
    width: em(111, $fz);
    height: em(111, $fz);

    @media (max-width: $media_extra_large) {
      width: em(62, $fz);
      height: em(62, $fz);
    }

    @media (max-width: $media_large) {
      width: em(38, $fz);
      height: em(38, $fz);
    }

    @media (max-width: $media_medium) {
      top: unset;
      bottom: em(-120, $fz);
      left: 0;
    }

    @media (max-width: $media_extra_small) {
      bottom: em(-40, $fz);
      left: em(30, $fz);
    }
  }


  &__2 {
    bottom: em(-315, $fz);
    left: em(19, $fz);
    z-index: 1;
    width: em(454, $fz);
    height: em(454, $fz);

    @media (max-width: $media_extra_large) {
      bottom: em(-411, $fz);
      width: em(305, $fz);
      height: em(305, $fz);
    }

    @media (max-width: $media_large) {
      bottom: em(-220, $fz);
      height: em(142, $fz);
    }

    @media (max-width: $media_medium) {
      right: 0;
      bottom: em(-170, $fz);
      left: unset;
      width: em(290, $fz);
      height: em(98, $fz);
    }

    @media (max-width: $media_small) {
      right: em(-150, $fz);
    }

    @media (max-width: $media_extra_small) {
      bottom: em(-70, $fz);
    }
  }

  &__3 {
    right: em(80, $fz);
    bottom: em(-170, $fz);
    width: em(51, $fz);
    height: em(51, $fz);

    @media (max-width: $media_extra_large) {
      width: em(62, $fz);
      height: em(62, $fz);
    }

    @media (max-width: $media_large) {
      bottom: em(60, $fz);
      width: em(38, $fz);
      height: em(38, $fz);
    }

    @media (max-width: $media_medium) {
      top: em(-130, $fz);
      right: 50%;
      bottom: unset;
    }

    @media (max-width: $media_extra_small) {
      right: 10%;
    }
  }

  &__line {
    top: em(141, $fz);
    right: 0;
    width: 51%;
    height: em(2, $fz);

    @media (max-width: $media_large) {
      width: 45%;
    }

    @media (max-width: $media_medium) {
      top: em(70, $fz);
      width: 58%;
    }
  }
}

.products {
  position: relative;
  min-height: em(1372, $fz);

  padding-top: em(235, $fz);
  padding-bottom: em(50, $fz);
  background-color: $black;
  color: $white;

  @media (max-width: $media_extra_large) {
    min-height: em(1090, $fz);

    padding-top: em(190, $fz);
  }

  @media (max-width: $media_large) {
    min-height: em(1042, $fz);
  }

  @media (max-width: $media_medium) {
    min-height: em(540, $fz);

    padding-top: em(85, $fz);
  }

  @media (max-width: $media_small) {
    padding-bottom: em(75, $fz);
  }



  &__title {
    $fz: 64;
    margin-bottom: em(194, $fz);
    font-size: em($fz, $fz_base);
    font-weight: 700;
    line-height: em(77, $fz);

    @media (max-width: $media_extra_large) {
      $fz: 48;
      margin-bottom: em(162, $fz);
      font-size: em($fz, $fz_base);
      line-height: em(58, $fz);
    }

    @media (max-width: $media_medium) {
      $fz: 36;
      margin-bottom: em(82, $fz);
      font-size: em($fz, $fz_base);
      line-height: em(44, $fz);
    }

    @media (max-width: $media_small) {
      max-width: em(230, $fz);
      margin-bottom: em(55, $fz);
    }
  }

  &__content {
    display: flex;
    align-items: flex-start;

    @media (max-width: $media_small) {
      flex-direction: column;
    }


  }

  &__items {
    margin-right: em(154, $fz);

    @media (max-width: $media_extra_large) {
      margin-right: em(100, $fz);
    }

    @media (max-width: $media_large) {
      margin-right: em(65, $fz);
    }

    @media (max-width: $media_medium) {
      margin-right: em(20, $fz);
    }

    @media (max-width: $media_small) {
      min-height: em(140, $fz);
      margin-bottom: em(25, $fz);

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: em(20, $fz);
      row-gap: em(10, $fz);
    }

    @media (max-width: $media_extra_small) {
      grid-template-columns: 1fr;
    }
  }

  &__item-first,
  &__item {
    min-width: em(519, $fz);

    border: 1px solid $white;

    @media (max-width: $media_extra_large) {
      min-width: em(387, $fz);
    }

    @media (max-width: $media_large) {
      min-width: em(270, $fz);
    }

    @media (max-width: $media_medium) {
      min-width: em(173, $fz);
    }

    @media (max-width: $media_extra_small) {
      max-width: em(160, $fz);
    }

    &:nth-child(n+2) {
      margin-top: em(30, $fz);

      @media (max-width: $media_medium) {
        margin-top: em(12, $fz);
      }

      @media (max-width: $media_small) {
        margin-top: 0;
      }
    }
  }

  &__item-first {
    display: none;

    @media (max-width: $media_extra_small) {
      order: -1;
      margin-bottom: em(16, $fz);

      display: block;
    }

  }


  &__item {
    @media (max-width: $media_extra_small) {
      &:first-child {
        display: none;
      }
    }
  }

  &__leads {
    max-width: em(830, $fz);

    @media (max-width: $media_extra_large) {
      max-width: em(468, $fz);
    }

    @media (max-width: $media_large) {
      min-width: em(330, $fz);
    }

    @media (max-width: $media_medium) {
      min-width: em(260, $fz);
    }

    @media (max-width: $media_extra_small) {
      order: -1;
      margin-bottom: em(36, $fz);
    }
  }
}

.products-decor {
  position: absolute;
  background-color: $accent;

  &__line {
    top: em(362, $fz);
    left: 0;
    width: 28%;
    height: em(2, $fz);

    @media (max-width: $media_extra_large) {
      top: em(280, $fz);
      width: 51%;
    }

    @media (max-width: $media_medium) {
      top: em(160, $fz);
      width: 80%;
    }

    @media (max-width: $media_small) {
      top: em(190, $fz);
      width: 45%;
    }
  }
}

.product {
  &__text {
    $fz: 24;
    margin-left: em(30, $fz);
    font-size: em($fz, $fz_base);
    font-weight: 500;
    line-height: em(29, $fz);

    @media (max-width: $media_extra_large) {
      $fz: 18;
      font-size: em($fz, $fz_base);
      line-height: em(21, $fz);
    }

    @media (max-width: $media_large) {
      $fz: 12;
      font-size: em($fz, $fz_base);
      line-height: em(15, $fz);
    }

    @media (max-width: $media_medium) {
      $fz: 10;
      font-size: em($fz, $fz_base);
      line-height: em(11, $fz);
    }
  }

  &__icon {
    $fz: 60;
    display: flex;
    align-items: center;
    font-size: em($fz, $fz_base);

    @media (max-width: $media_extra_large) {
      $fz: 50;
      font-size: em($fz, $fz_base);
    }

    @media (max-width: $media_large) {
      $fz: 40;
      font-size: em($fz, $fz_base);
    }

    @media (max-width: $media_medium) {
      $fz: 20;
      font-size: em($fz, $fz_base);
    }
  }

  &__link {
    display: flex;
    align-items: center;

    padding: em(5 25, $fz);

    @media (max-width: $media_large) {
      padding-left: em(13, $fz);
    }

    @media (max-width: $media_medium) {
      padding: em(9 13, $fz);
    }

    &:hover {
      background-color: $white;
      color: $black;
    }
  }
}

.leads {
  &__title {
    $fz: 48;
    margin-bottom: em(50, $fz);
    color: $accent;
    font-size: em($fz, $fz_base);
    font-weight: 500;
    line-height: em(56, $fz);

    @media (max-width: $media_extra_large) {
      $fz: 36;
      margin-bottom: em(30, $fz);
      font-size: em($fz, $fz_base);
      line-height: em(42, $fz);
    }

    @media (max-width: $media_medium) {
      $fz: 18;
      margin-bottom: em(25, $fz);
      font-size: em($fz, $fz_base);
      line-height: em(21, $fz);
    }
  }

  &__item {
    &:nth-child(n+2) {
      margin-top: em(30, $fz);

      @media (max-width: $media_medium) {
        margin-top: em(10, $fz);
      }
    }
  }

  &__text {
    $fz: 24;
    position: relative;

    padding-left: em(20, $fz);
    font-size: em($fz, $fz_base);
    line-height: em(27, $fz);

    @media (max-width: $media_extra_large) {
      $fz: 18;
      margin-bottom: em(25, $fz);
      font-size: em($fz, $fz_base);
      line-height: em(21, $fz);

      br {
        display: none;
      }
    }

    @media (max-width: $media_medium) {
      $fz: 12;
      margin-bottom: em(10, $fz);
      font-size: em($fz, $fz_base);
      line-height: em(14, $fz);
    }

    &::before {
      position: absolute;
      top: em(14, $fz);
      left: 0;
      width: em(10, $fz);
      height: 1px;

      content: '';

      display: inline-block;
      background: currentColor;

      @media (max-width: $media_extra_large) {
        top: em(10, $fz);
      }

      @media (max-width: $media_large) {
        width: em(8, $fz);
      }

      @media (max-width: $media_medium) {
        width: em(4, $fz);
      }
    }
  }
}

.affiliates {
  padding-top: em(146, $fz);
  padding-bottom: em(50, $fz);

  @media (max-width: $media_extra_large) {
    padding-top: em(135, $fz);
    padding-bottom: em(135, $fz);
  }

  @media (max-width: $media_large) {
    padding-top: em(39, $fz);
    padding-bottom: em(290, $fz);
  }

  @media (max-width: $media_medium) {
    padding-top: em(57, $fz);
  }

  @media (max-width: $media_small) {
    padding-top: em(26, $fz);
  }

  &__container {
    margin-bottom: em(-170, $fz);

    @media (max-width: $media_large) {
      margin-bottom: em(-460, $fz);
    }

    @media (max-width: $media_large) {
      margin-bottom: em(-395, $fz);
    }
  }

  &__title {
    $fz: 64;
    max-width: em(750, $fz);
    font-size: em($fz, $fz_base);
    font-weight: 700;
    line-height: em(77, $fz);

    @media (max-width: 1600px) {
      max-width: em(500, $fz);
    }

    @media (max-width: $media_extra_large) {
      $fz: 48;
      max-width: em(351, $fz);
      font-size: em($fz, $fz_base);
      line-height: em(58, $fz);
    }

    @media (max-width: $media_large) {
      max-width: none;

      br {
        display: none;
      }
    }

    @media (max-width: $media_medium) {
      $fz: 36;
      max-width: em(310, $fz);
      font-size: em($fz, $fz_base);
      line-height: em(43, $fz);
    }

    @media (max-width: $media_small) {
      max-width: 100%;
      text-align: center;
    }

    @media (max-width: $media_extra_small) {
      max-width: em(205, $fz);
      text-align: left;
    }
  }

  &__items {
    position: relative;
    z-index: 2;
    max-width: em(788, $fz);
    min-height: em(715, $fz);
    margin-top: em(-437, $fz);
    margin-left: auto;

    display: grid;
    justify-content: center;
    grid-template-columns: repeat(3, em(145, $fz));
    column-gap: em(70, $fz);
    padding: em(160 116, $fz);
    background-color: $accent;
    color: $white;
    row-gap: em(45, $fz);

    @media (max-width: $media_extra_large) {
      max-width: em(550, $fz);
      min-height: em(500, $fz);
      margin-top: em(-332, $fz);

      grid-template-columns: repeat(3, em(117, $fz));
      column-gap: em(50, $fz);
      padding: em(100 50, $fz);
      row-gap: em(40, $fz);
    }

    @media (max-width: $media_large) {
      max-width: em(503, $fz);
      min-height: em(387, $fz);
      margin-top: em(52, $fz);

      column-gap: em(30, $fz);
      padding: em(45 40, $fz);
    }

    @media (max-width: $media_medium) {
      max-width: em(251, $fz);
      min-height: em(387, $fz);
      margin-top: em(20, $fz);

      grid-template-columns: repeat(2, em(60, $fz));
      column-gap: em(38, $fz);
      padding: em(35 47, $fz);
      row-gap: em(25, $fz);
    }

    @media (max-width: $media_small) {
      max-width: em(280, $fz);
      margin-right: auto;

      padding: em(30 60, $fz);
    }

    @media (max-width: $media_extra_small) {
      position: relative;
      left: 50%;
      width: 100vw;
      max-width: 100vw;
      margin-right: 0;

      column-gap: em(62, $fz);
      transform: translateX(-50%);

    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.affiliat {
  &__icon {
    $fz: 115;
    font-size: em($fz, $fz_base);

    @media (max-width: $media_extra_large) {
      $fz: 81;
      font-size: em($fz, $fz_base);
    }

    @media (max-width: $media_medium) {
      $fz: 60;
      font-size: em($fz, $fz_base);
    }
  }

  &__text {
    $fz: 24;
    text-align: center;
    font-size: em($fz, $fz_base);
    line-height: em(28, $fz);

    @media (max-width: $media_extra_large) {
      $fz: 18;
      font-size: em($fz, $fz_base);
      line-height: em(20, $fz);
    }

    @media (max-width: $media_medium) {
      $fz: 12;
      font-size: em($fz, $fz_base);
      line-height: em(14, $fz);
    }
  }
}

.affiliates-decor {
  position: absolute;
  background-color: $accent;

  &__line {
    bottom: em(231, $fz);
    left: 0;
    width: 87%;
    height: em(2, $fz);
    transform: translateX(-100%);

    @media (max-width: 1600px) {
      bottom: em(150, $fz);
    }

    @media (max-width: $media_extra_large) {
      bottom: em(70, $fz);
      width: 54%;
    }

    @media (max-width: $media_large) {
      display: none;
    }
  }

  &__1 {
    top: em(-65, $fz);
    right: em(-65, $fz);
    width: em(130, $fz);
    height: em(130, $fz);
    background-color: $white;

    @media (max-width: 1780px) {
      top: em(-10, $fz);
      right: em(-10, $fz);
      width: em(49, $fz);
      height: em(49, $fz);
    }

    @media (max-width: $media_extra_large) {
      top: em(-35, $fz);
      right: em(-35, $fz);
    }

    @media (max-width: $media_large) {
      top: unset;
      bottom: em(-35, $fz);
      left: em(-35, $fz);
    }

    @media (max-width: $media_medium) {
      right: em(-24, $fz);
      bottom: em(-19, $fz);
      left: unset;
    }

    @media (max-width: $media_extra_small) {
      right: em(24, $fz);
      width: em(40, $fz);
      height: em(40, $fz);
    }
  }

  &__2 {
    @media (max-width: $media_medium) {
      top: em(110, $fz);
      right: unset;
      left: em(-190, $fz);
      width: em(49, $fz);
      height: em(49, $fz);
      background-color: $accent;
    }

    @media (max-width: $media_small) {
      top: em(-138, $fz);
      right: em(0, $fz);
      left: unset;
      width: em(23, $fz);
      height: em(23, $fz);
    }

    @media (max-width: $media_extra_small) {
      top: em(-225, $fz);
    }
  }

  &__3 {
    @media (max-width: $media_medium) {
      top: em(44, $fz);
      right: unset;
      left: em(-72, $fz);
      width: em(19, $fz);
      height: em(19, $fz);
      background-color: $accent;
    }

    @media (max-width: $media_small) {
      top: em(102, $fz);
      left: em(-52, $fz);
    }

    @media (max-width: $media_extra_small) {
      top: em(-58, $fz);
      left: em(250, $fz);
    }
  }
}

.advantages {
  position: relative;
  z-index: 1;

  padding-top: em(340, $fz);
  padding-bottom: em(220, $fz);
  background-color: $black;
  color: $white;

  @media (max-width: $media_extra_large) {
    padding-top: em(160, $fz);
    padding-bottom: em(160, $fz);
  }

  @media (max-width: $media_large) {
    padding-top: em(283, $fz);
    padding-bottom: em(150, $fz);
  }

  @media (max-width: $media_medium) {
    padding-top: em(170, $fz);
    padding-bottom: em(80, $fz);
  }

  @media (max-width: $media_small) {
    padding-top: em(180, $fz);
  }

  &__container {
    max-width: em(1330, $fz);

    @media (max-width: $media_extra_large) {
      max-width: em(956, $fz);
    }

    @media (max-width: $media_large) {
      max-width: em(590, $fz);
    }

    @media (max-width: $media_medium) {
      max-width: em(468, $fz);
    }

    @media (max-width: $media_small) {
      max-width: em(340, $fz);
    }
  }

  &__title {
    $fz: 64;
    margin-bottom: em(120, $fz);
    text-align: center;
    font-size: em($fz, $fz_base);
    font-weight: 700;
    line-height: em(77, $fz);

    @media (max-width: $media_extra_large) {
      $fz: 48;
      margin-bottom: em(100, $fz);
      font-size: em($fz, $fz_base);
      line-height: em(58, $fz);
    }

    @media (max-width: $media_medium) {
      $fz: 36;
      margin-bottom: em(60, $fz);
      font-size: em($fz, $fz_base);
      line-height: em(43, $fz);
    }
  }

  &__list {
    display: flex;

    @media (max-width: $media_large) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__items {
    &:nth-child(even) {
      margin-left: em(338, $fz);

      @media (max-width: $media_extra_large) {
        margin-left: em(20, $fz);
      }

      @media (max-width: $media_large) {
        margin-left: 0;
      }
    }
  }

  &__item {
    max-width: em(480, $fz);
    margin-bottom: em(50, $fz);

    display: flex;

    @media (max-width: $media_extra_large) {
      margin-bottom: em(40, $fz);
    }

    @media (max-width: $media_large) {
      max-width: em(400, $fz);
    }

    @media (max-width: $media_medium) {
      max-width: em(365, $fz);
    }

    @media (max-width: $media_small) {
      max-width: em(285, $fz);
    }
  }
}

.advantage {
  &__text {
    $fz: 24;
    margin-left: em(27, $fz);
    font-size: em($fz, $fz_base);
    line-height: em(27, $fz);

    @media (max-width: $media_extra_large) {
      $fz: 18;
      font-size: em($fz, $fz_base);
      line-height: em(20, $fz);
    }

    @media (max-width: $media_medium) {
      $fz: 14;
      font-size: em($fz, $fz_base);
      line-height: em(16, $fz);
    }

    &--header {
      margin-bottom: em(20, $fz);

      @media (max-width: $media_extra_large) {
        margin-bottom: em(15, $fz);
      }
    }
  }
}

.checkmark {
  display: inline-block;

  &::after {
    width: em(16, $fz);
    height: em(32, $fz);

    content: '';

    display: block;
    border: solid $accent;
    border-width: em(0 4 4 0, $fz);
    transform: rotate(45deg);

    @media (max-width: $media_extra_large) {
      width: em(12, $fz);
      height: em(22, $fz);

      border-width: em(0 3 3 0, $fz);
    }

    @media (max-width: $media_medium) {
      margin-top: em(-10, $fz);
    }

    @media (max-width: $media_small) {
      margin-top: em(-5, $fz);
    }
  }
}

.advertisers {
  position: relative;
  min-height: em(800, $fz);

  padding-top: em(190, $fz);
  padding-bottom: em(100, $fz);
  background: url('/img/advertisers-bg.jpg') center center / cover no-repeat, $black;
  color: $white;

  @media (max-width: $media_extra_large) {
    padding-top: em(79, $fz);
  }

  @media (max-width: $media_medium) {
    min-height: em(750, $fz);

    padding-bottom: 0;
    background-position: top;
    background-size: auto em(350, $fz);
  }

  @media (max-width: $media_small) {
    padding-top: em(53, $fz);
  }

  @media (max-width: $media_extra_small) {
    background-size: auto em(400, $fz);
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    content: '';

    display: block;
    background-image: linear-gradient(90.12deg, rgba(0, 0, 0, 0.8) 110.81%, rgba(0, 0, 0, 0) 145.19%);
  }

  &__container {
    margin-bottom: em(-500, $fz);

    display: flex;

    @media (max-width: $media_large) {
      margin-top: em(20, $fz);

      flex-direction: column;
      padding: 0;
    }

    @media (max-width: $media_medium) {
      margin-bottom: 0;
    }
  }

  &__header {
    position: relative;
    max-width: em(730, $fz);
    margin-right: em(80, $fz);

    padding-bottom: em(50, $fz);

    @media (max-width: 1625px) {
      max-width: em(530, $fz);
      margin-right: 0;
    }

    @media (max-width: $media_medium) {
      width: 100%;
      max-width: 100%;
    }

    @media (max-width: $media_small) {
      margin-left: 0;

      padding-right: em(15, $fz);
      padding-left: em(15, $fz);
    }
  }

  &__title {
    $fz: 64;
    margin-bottom: em(30, $fz);
    font-size: em($fz, $fz_base);
    font-weight: 700;
    line-height: em(75, $fz);

    @media (max-width: 1625px) {
      $fz: 48;
      font-size: em($fz, $fz_base);
      line-height: em(56, $fz);
    }

    @media (max-width: $media_medium) {
      $fz: 36;
      font-size: em($fz, $fz_base);
      line-height: em(42, $fz);
    }
  }

  &__subtitle {
    $fz: 24;
    font-size: em($fz, $fz_base);
    line-height: em(28, $fz);

    @media (max-width: 1625px) {
      $fz: 18;
      font-size: em($fz, $fz_base);
      line-height: em(20, $fz);
    }

    @media (max-width: $media_medium) {
      $fz: 14;
      font-size: em($fz, $fz_base);
      line-height: em(16, $fz);
    }
  }

  &__form {
    width: 100%;
    max-width: em(900, $fz);
    min-height: em(1540, $fz);
    margin-top: em(-628, $fz);
    margin-right: em(-110, $fz);


    padding: em(670 260 525 128, $fz);
    transform: rotate(21deg);

    @media (max-width: 1625px) {
      max-width: em(720, $fz);

      padding-right: em(110, $fz);
    }

    @media (max-width: $media_extra_large) {
      max-width: em(665, $fz);
      margin-top: em(-420, $fz);
      margin-right: em(-160, $fz);

      padding: em(626 135 525 85, $fz);
      transform: rotate(27deg);
    }

    @media (max-width: 1200px) {
      right: em(-120, $fz);

      padding-right: em(200, $fz);
    }

    @media (max-width: $media_large) {
      max-width: em(722, $fz);
      min-height: em(390, $fz);
      margin-top: auto;
      margin-left: auto;

      padding: em(36 205 28 58, $fz);
      transform: rotate(0deg);
    }

    @media (max-width: $media_medium) {
      position: relative;
      left: 50%;
      width: 100vw;
      max-width: 100vw;
      margin-left: 0;

      padding-right: 0;
      padding-left: 0;
      transform: translateX(-50%);
    }

    @media (max-width: $media_extra_small) {
      padding-right: em(20, $fz);
      padding-left: em(20, $fz);
    }
  }
}

.form-advertisers {
  background-color: $white;
  color: $black;

  &__content {
    transform: rotate(-21deg);

    @media (max-width: $media_extra_large) {
      transform: rotate(-26deg);
    }

    @media (max-width: $media_large) {
      transform: rotate(0deg);
    }

    @media (max-width: $media_medium) {
      max-width: em(444, $fz);
      margin-right: auto;
      margin-left: auto;
    }

    @media (max-width: $media_small) {
      max-width: em(340, $fz);
    }

  }

  &__header {
    max-width: em(400, $fz);

    @media (max-width: $media_extra_large) {
      max-width: em(320, $fz);
    }
  }

  &__title {
    $fz: 24;
    margin-bottom: em(40, $fz);
    color: $accent;
    font-size: em($fz, $fz_base);
    font-weight: 500;
    line-height: em(28, $fz);

    @media (max-width: $media_extra_large) {
      $fz: 18;
      margin-bottom: em(30, $fz);
      font-size: em($fz, $fz_base);
      line-height: em(21, $fz);
    }
  }

  &__subtitle {
    $fz: 18;
    margin-bottom: em(30, $fz);
    font-size: em($fz, $fz_base);
    line-height: em(21, $fz);

    @media (max-width: $media_extra_large) {
      $fz: 14;
      font-size: em($fz, $fz_base);
      line-height: em(16, $fz);
    }
  }

  &__input {
    $fz: 14;
    width: 100%;
    min-height: em(48, $fz);

    padding: em(10 16, $fz);
    border: 1px solid rgba(0, 0, 0, 0.15);
    color: $black;
    font-size: em($fz, $fz_base);
    line-height: em(16, $fz);

    &::placeholder {
      color: inherit;
    }
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: em(20, $fz);

    @media (max-width: $media_small) {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    position: relative;

    &--required {
      &::after {
        $fz: 14;
        position: absolute;
        top: em(10, $fz);
        right: em(8, $fz);

        content: '*';
        color: $accent;
        font-size: em($fz, $fz_base);
      }
    }
  }

  &__fieldset {
    margin-bottom: em(15, $fz);

    @media (max-width: $media_large) {
      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }

  &__textarea {
    min-height: em(80, $fz);
    margin-bottom: em(15, $fz);
  }

  &__btns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: em(20, $fz);
    margin-top: em(15, $fz);

    @media (max-width: $media_extra_small) {
      grid-template-columns: 1fr;
      row-gap: em(35, $fz);
    }
  }
}

.advertisers-decor {
  position: absolute;
  background-color: $white;

  &__line {
    top: em(-19, $fz);
    right: 0;
    z-index: 1;
    width: 71%;
    height: em(20, $fz);

    @media (max-width: $media_extra_large) {
      width: 52%;
    }

    @media (max-width: $media_small) {
      width: 20%;
    }

    @media (max-width: $media_extra_small) {
      top: em(-8, $fz);
      width: 70%;
      height: em(7, $fz);
    }
  }
}

.careers {
  position: relative;
  z-index: 1;

  min-height: em(1190, $fz);

  padding-top: em(215, $fz);
  padding-bottom: em(50, $fz);
  background-color: $black;
  color: $white;

  @media (max-width: $media_extra_large) {
    min-height: em(1115, $fz);

    padding-top: em(162, $fz);
  }

  @media (max-width: $media_medium) {
    padding-top: em(74, $fz);
  }

  @media (max-width: $media_small) {
    padding-top: em(71, $fz);
  }

  &__title {
    $fz: 64;
    margin-bottom: em(50, $fz);
    font-size: em($fz, $fz_base);
    font-weight: 700;
    line-height: em(75, $fz);

    @media (max-width: $media_extra_large) {
      $fz: 48;
      margin-bottom: em(30, $fz);
      font-size: em($fz, $fz_base);
      line-height: em(56, $fz);
    }

    @media (max-width: $media_medium) {
      $fz: 36;
      margin-bottom: em(25, $fz);
      font-size: em($fz, $fz_base);
      line-height: em(42, $fz);
    }
  }

  &__header {
    margin-bottom: em(75, $fz);

    display: flex;

    @media (max-width: $media_extra_large) {
      margin-bottom: em(126, $fz);
    }


    @media (max-width: $media_large) {
      margin-bottom: em(75, $fz);

      flex-direction: column;

    }

    @media (max-width: $media_medium) {
      margin-bottom: em(86, $fz);
    }

    @media (max-width: $media_small) {
      margin-bottom: em(56, $fz);
    }
  }

  &__text {
    $fz: 24;
    color: $accent;
    font-size: em($fz, $fz_base);
    font-weight: 500;
    line-height: em(28, $fz);

    @media (max-width: $media_extra_large) {
      $fz: 18;
      font-size: em($fz, $fz_base);
      line-height: em(21, $fz);
    }

    @media (max-width: $media_medium) {
      $fz: 14;
      font-size: em($fz, $fz_base);
      line-height: em(16, $fz);
    }
  }

  &__email {
    $fz: 24;
    margin-right: em(140, $fz);
    margin-left: auto;
    font-size: em($fz, $fz_base);
    font-weight: 400;
    line-height: em(28, $fz);

    @media (max-width: $media_extra_large) {
      $fz: 18;
      margin-right: 0;
      font-size: em($fz, $fz_base);
      line-height: em(21, $fz);
    }

    @media (max-width: $media_large) {
      margin-top: em(30, $fz);
      margin-left: 0;
    }

    @media (max-width: $media_medium) {
      $fz: 14;
      font-size: em($fz, $fz_base);
      line-height: em(16, $fz);
    }

    @media (max-width: $media_small) {
      margin-top: em(15, $fz);
    }
  }

  &__content {
    display: flex;

    @media (max-width: $media_large) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__subtitle {
    $fz: 36;
    margin-bottom: em(33, $fz);
    font-size: em($fz, $fz_base);
    font-weight: 500;
    line-height: em(40, $fz);

    @media (max-width: $media_extra_large) {
      $fz: 24;
      margin-bottom: em(45, $fz);
      font-size: em($fz, $fz_base);
      line-height: em(26, $fz);
    }

    @media (max-width: $media_medium) {
      $fz: 18;
      margin-bottom: em(25, $fz);
      font-size: em($fz, $fz_base);
      line-height: em(20, $fz);
    }
  }

  &__jobs {
    @media (max-width: $media_small) {
      position: relative;
      left: 50%;
      width: 100vw;
      max-width: 100vw;
      transform: translateX(-50%);
    }

    @media (max-width: $media_extra_small) {
      left: 29%;
      //max-width: em(212, $fz);
      width: 66vw;
    }
  }
}

.careers-decor {
  position: absolute;
  background-color: $accent;

  &__line {
    top: em(213, $fz);
    right: 0;
    width: 56%;
    height: em(2, $fz);

    @media (max-width: $media_extra_large) {
      top: em(160, $fz);
      width: 35%;
    }

    @media (max-width: $media_medium) {
      top: em(80, $fz);
      width: 30%;
    }

    @media (max-width: $media_small) {
      top: em(70, $fz);
      width: 50%;
    }
  }
}

.careers-link {
  &__caption {
    color: $accent;
  }
}

.jobs {
  height: 100%;

  background-color: $white;
  color: $black;

  &__form {
    min-width: em(300, $fz);
    min-height: em(460, $fz);

    padding: em(40, $fz);

    @media (max-width: $media_large) {
      min-width: em(502, $fz);
      min-height: em(280, $fz);

      display: flex;
      flex-wrap: wrap;
    }

    @media (max-width: $media_medium) {
      min-width: em(444, $fz);
      min-height: em(276, $fz);
    }

    @media (max-width: $media_small) {
      min-width: 100%;
      margin-right: auto;
      margin-left: auto;

      justify-content: center;
      padding-right: 0;
      padding-left: 0;
    }

    @media (max-width: $media_extra_small) {
      flex-direction: column;
      padding-left: em(22, $fz);
    }
  }
}

.form-jobs {
  &__legend {
    margin-bottom: em(30, $fz);

    display: flex;
    align-items: center;
  }

  &__legend-text {
    $fz: 18;
    margin-left: em(8, $fz);
    font-size: em($fz, $fz_base);
    line-height: em(30, $fz);

    @media (max-width: $media_medium) {
      $fz: 14;
      font-size: em($fz, $fz_base);
      line-height: em(23, $fz);
    }
  }

  &__icon {
    $fz: 22;
    color: $accent;
    font-size: em($fz, $fz_base);

    @media (max-width: $media_medium) {
      $fz: 20;
      font-size: em($fz, $fz_base);
    }
  }

  &__fieldset {
    &:nth-child(n+2) {
      margin-top: em(60, $fz);

      @media (max-width: $media_large) {
        margin-top: 0;
        margin-left: em(90, $fz);
      }

      @media (max-width: $media_medium) {
        margin-left: em(55, $fz);
      }

      @media (max-width: $media_extra_small) {
        margin-top: em(40, $fz);
        margin-left: 0;
      }
    }

    &--select {
      @media (max-width: $media_large) {
        width: 100%;
        max-width: em(212, $fz);
      }

      @media (max-width: $media_medium) {
        max-width: em(210, $fz);
      }

      @media (max-width: $media_small) {
        max-width: em(160, $fz);
      }
    }
  }

  &__item {
    &:nth-child(n+2) {
      margin-top: em(30, $fz);

      @media (max-width: $media_medium) {
        margin-top: em(20, $fz);
      }
    }
  }
}

.form-jobs-item {
  &__input {
    position: absolute;
    appearance: none;

    &:focus + .form-jobs-item__box {
      outline: 1px solid $accent;
    }

    & + .form-jobs-item__box {
      background-image: url(/img/check.svg);
    }

    &:checked + .form-jobs-item__box {
      background-image: url(/img/check-check.svg);
    }
  }

  &__box {
    position: absolute;
    width: em(24, $fz);
    height: em(24, $fz);
    margin-left: em(-40, $fz);
  }

  &__label {
    margin-left: em(40, $fz);

    display: flex;
    align-items: center;
  }

  &__text {
    $fz: 14;
    font-size: em($fz, $fz_base);
    line-height: em(23, $fz);
  }
}

.vacancies {
  &__items {
    margin-left: em(105, $fz);

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: em(155, $fz);
    row-gap: em(63, $fz);

    @media (max-width: $media_extra_large) {
      grid-template-columns: 1fr;
      row-gap: em(80, $fz);
    }

    @media (max-width: $media_large) {
      margin-top: em(66, $fz);
      margin-left: 0;
    }

    @media (max-width: $media_medium) {
      margin-top: em(60, $fz);
    }
  }

  &__item {
    padding-bottom: em(16, $fz);
    border-bottom: 1px solid $accent;

    @media (max-width: $media_large) {
      max-width: em(550, $fz);
    }

    @media (max-width: $media_medium) {
      max-width: em(444, $fz);
    }
  }
}

.vacancy {
  &__title {
    $fz: 32;
    margin-bottom: em(10, $fz);
    font-size: em($fz, $fz_base);
    font-weight: 500;
    line-height: em(40, $fz);

    @media (max-width: $media_extra_large) {
      $fz: 24;
      margin-bottom: em(14, $fz);
      font-size: em($fz, $fz_base);
      line-height: em(30, $fz);
    }
  }

  &__icon {
    $fz: 20;
    color: $accent;
    font-size: em($fz, $fz_base);
  }

  &__location {
    $fz: 14;
    margin-left: em(8, $fz);
    font-size: em($fz, $fz_base);
    line-height: em(17, $fz);
  }

  &__locality {
    margin-bottom: em(31, $fz);

    display: flex;
    align-items: center;
  }

  &__description {
    $fz: 14;
    font-size: em($fz, $fz_base);
    line-height: em(17, $fz);
  }
}

.footer {
  padding-top: em(5, $fz);
  padding-bottom: em(5, $fz);
  background-color: $black;
  color: $white;

  @media (max-width: $media_medium) {
    padding-bottom: em(27, $fz);
  }

  &__container {
    display: flex;
    align-items: center;

    @media (max-width: $media_small) {
      flex-direction: column;
    }
  }

  &__nav {
    margin-right: auto;
    margin-left: auto;

    @media (max-width: $media_small) {
      order: -1;
      margin-top: em(20, $fz);
      margin-bottom: em(20, $fz);
    }
  }

  &__logo {
    flex: none;

    @media (max-width: $media_small) {
      margin-bottom: em(20, $fz);
    }
  }

  &__nav-list {
    @media (max-width: $media_medium) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__nav-item {
    //@media (max-width: 1680px) {
    //  &:nth-child(n+2) {
    //    margin-left: em(30, $fz);
    //  }
    //}

    @media (max-width: $media_medium) {
      &:nth-child(n+2) {
        margin-top: em(10, $fz);
        margin-left: 0;
      }
    }
  }
}

.footer-nav {
  &__link {
    min-height: em(100, $fz);

    @media (max-width: $media_extra_large) {
      $fz: 14;
      min-height: em(78, $fz);
      font-size: em($fz, $fz_base);
      line-height: em(15, $fz);
    }

    @media (max-width: $media_large) {
      $fz: 9;
      font-size: em($fz, $fz_base);
      line-height: em(11, $fz);
    }

    @media (max-width: $media_medium) {
      min-height: em(10, $fz);
    }

  }
}

.social-network {
  display: flex;
  align-items: center;

  &__item {
    color: $accent;

    &:nth-child(n+2) {
      margin-left: em(28, $fz);

      @media (max-width: $media_large) {
        margin-left: em(18, $fz);
      }
    }
  }

  &__icon {
    $fz: 22;
    font-size: em($fz, $fz_base);

    @media (max-width: $media_large) {
      $fz: 17;
      font-size: em($fz, $fz_base);
    }
  }

  &__link {
    &:hover {
      color: $white;
      stroke: $accent;
    }
  }
}
